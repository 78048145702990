export default {
  account: {
    clients: {
      list: {
        importTitle: 'New! You can now ask your clients for reviews.',
        importDescription:
          'Increase your closing rate! Soon you will be able to present your reviews directly on your proposals and public company page.',
        importChatMessage:
          'Hi! I would like to add my reviews from the following link:[Paste your link here]',
        importLink: 'Import reviews from Google etc.',
        createFirstClient: 'Create your first client',
        noResult: 'No results found.',
        noResultDescription:
          'Create a client so you can easily find them when creating a project.',
        createAClient: 'Create client',
        resetSearch: 'Reset search'
      },
      notePlaceholder: 'Only visible to you and your colleagues',
      noProjectTitle: 'There are no project for this client',
      noProjectSubTitle: 'You have not created any project for this client yet.'
    },
    overview: {
      current: 'Current',
      archive: 'Archive',
      statistics: 'Statistics'
    },
    project: {
      error: "Couldn't find project",
      list: {
        sortOptions: {
          updatedAt: 'Last modified',
          createdAt: 'Created date'
        },
        noArchived: 'You have no archived projects',
        noProjects: 'You have no projects',
        radioProject: 'Show project name first',
        radioAddress: 'Show address first',
        filterCreatorButton: 'Created by'
      },
      calloutScheduledDelivery: {
        title: 'Scheduled delivery',
        description: 'The document "{{docName}}" will be sent',
        cancel: 'Abort delivery',
        deliveryDate: 'delivery scheduled',
        dialog: {
          title: 'Remove scheduled delivery',
          message: 'Are you sure that you want to abort the send out{{for}}?',
          forName: 'for {{name}}',
          cancel: 'No',
          submit: 'Yes, remove.'
        }
      },
      clients: {
        condominiumId: 'Apartment number',
        condominiumOrgNo: 'Housing association organisation number',
        propertyId: 'Designation',
        buttonClient: 'Go to client',
        buttonPropertyDesignation: 'Property designation'
      },
      createDocumentDropdown: {
        mobileLabel: 'What type of document?',
        active: 'What type of document?',
        inActive: 'New document',
        ataTooltip:
          '🔗 Create add on agreement that will be linked to the document.',
        regularTooltip: '📑 Create document connected to project.',
        regular: 'Ordinary document'
      },
      secondaryDropdown: {
        mobileLabel: 'What to do you want to invoice?',
        active: 'What to invoice?',
        inActive: 'Invoice',
        connectedTooltip: 'Invoice a document ⚡️',
        printActive: 'Select document'
      },
      file: {
        addButton: 'Add attachment'
      },
      member: {
        leader: 'Leader',
        added: 'Added',
        promoteButton: 'Assign leadership',
        promoteTooltipLeader: 'Is already project leader',
        promoteTooltip: 'User permission is missing',
        removeButton: 'Remove from project',
        removeTooltip: 'Mark another member as project leader first',
        validation: {
          required: 'Enter a valid email',
          email: 'Enter a valid email',
          unique: 'The user is already invited'
        }
      },
      taskList: {
        placeholder: 'Write task',
        draggingHint: 'Release outside of window to remove'
      },
      taskModal: {
        placeholder: 'Write something you want to be done'
      },
      propertyDesignationModal: {
        alt1Title: 'Ask client',
        alt1Text: 'Send message to client asking them to supply info',
        alt2Title: 'Add info',
        alt2Text: 'Add information about your clients property designation',
        sentTitle: 'Message sent!',
        sentText:
          'Your client will receive an email where they can enter their information'
      },
      propertyDesignationModalEdit: {
        title: 'Add property designation',
        realEstateRadio: 'Property/Villa',
        condominiumRadio: 'Condominium',
        condominiumId: {
          label: 'Apartment number',
          placeholder: 'ApartmentNo, 4 numbers'
        },
        condominiumOrgNo: {
          label: 'Housing associations registered number',
          placeholder: 'Org-nr, 10 numbers'
        },
        validations: {
          realEstateId: {
            required: 'Add property designation'
          },
          condominiumId: {
            required: 'Add apartment number',
            numeric: 'Only numbers allowed',
            minLength: 'Enter 4 numbers',
            maxLength: 'Enter 4 numbers'
          },
          condominiumOrgNo: {
            required: 'Enter housing associations registered number'
          }
        }
      },
      onboardingModal: {
        title: 'Well done!',
        description:
          'It only took you <strong>{{timeToComplete}}</strong> to send your first proposal!',
        descriptionFallback:
          'You sent your first proposal. It was simple, right?',
        tip: 'Psst! It will go even faster when you begin using templates.',
        callout: {
          title: 'Your gift',
          description:
            "You've received another 5 days of unlimited access! You have a total of {{amount}}"
        }
      }
    },
    onboarding: {
      submitButton: 'Go to my account',
      stepOne: {
        title: 'Welcome! 👋',
        subTitle:
          "Ready to send professional proposals & contracts? We sure are! We'll start with setting up your theme.",
        questionTitle: 'Which theme suits you best?',
        continueText: 'You can change this later.'
      },
      stepTwo: {
        title: '1 picture > 1 000 words',
        subTitle:
          'Like the expression goes, *cough* almost. What tells your story better than a background image?',
        questionTitle: 'Suggested images',
        continueText:
          'You can find more theme settings under Settings -> Profile.'
      },
      panelDevice: {
        documentTitle: 'Consulting assignment',
        priceBlockOptions: '+2 selectable options',
        personalMessage:
          "Hello Peter!<br> Great meeting last time. Here is a proposal according to what we discussed. In addition there is also 2 selectable options which I'd recommend.",
        parties: 'Parties',
        scopeOfWork: 'Scope of work'
      }
    },
    convertStripe: {
      title: 'Add credit card',
      description:
        'Change payment method to credit card if you wish to continue to use {{brand,capitalize}}. We do not support monthly payments by invoice any more.',
      button: 'Add credit card'
    },
    unpaidStripe: {
      description: 'There are unpaid invoices for your subscription to manage',
      button: 'Show'
    },
    resurrectTrial: {
      title: `Welcome back to $t(brand.name)`,
      expired: 'Your unlimited test-period expired {{- expiredAt}}.',
      startAgain: 'Start another 14 day trial for your team.',
      buttonText: 'Reset trial',
      hintText:
        'After the trial your account will change plan to the free version.',
      successMessage: 'Unlimited access activated! Expires in 14 days'
    },
    settings: {
      avatarUploadButton: 'Upload profile picture',
      baseLeaveDialog: {
        title: 'Sure you want to cancel?',
        message: 'You have unsaved changes',
        cancel: 'Stay',
        submit: 'Leave page'
      },
      templateList: {
        calloutTitle: 'Create Template',
        calloutLink: 'Create template'
      },
      photosVideos: {
        dropText: 'Drag & drop files to upload',
        emptyText: 'You haven’t uploaded any photos',
        namePlaceholder: 'Album name',
        descriptionPlaceholder:
          'Describe the photos. E.g. the type of work, materials used etc.',
        validations: {
          name: {
            required: 'Name album'
          }
        }
      },
      certificates: {
        searchPlaceholder: 'Search certificate or choose from list',
        noResults: 'No certificate found',
        callout: {
          title: 'Professional certificates & competence',
          description:
            'Most clients prefer professional contractors with licenses within their respective field.'
        },
        titles: {
          certificate: 'Certificates',
          tradeOrganization: 'Trade associations',
          award: 'Awards',
          authorization: 'Authorisations',
          competence: 'Comptences',
          other: 'Other'
        }
      },
      company: {
        type: {
          label: 'Company form',
          placeholder: 'Choose form'
        },
        region: {
          label: 'Operating region',
          tooltip: 'Decides which region specific features will be active'
        },
        fTaxLabel: 'Registered for company tax',
        insuranceLabel: 'Have company insurance',
        successMessage: 'Company settings saved',
        validations: {
          name: {
            required: 'Add company name',
            maxLengthValue: 'Max {{maxLength}} characters'
          },
          email: {
            required: 'Add company email',
            email: 'Your email must be on the format name@example.com'
          },
          organisationNumber: {
            isValidOrgNoWrapper: 'Add a valid company organisation number'
          },
          street: {
            required: 'Add company address'
          },
          zip: {
            required: 'Add zip code'
          },
          city: {
            required: 'Add city'
          }
        }
      },
      presentation: {
        label: 'Presentation',
        placeholder:
          'Explain what makes your company stand out and why your services are excellent.',
        successMessage: 'Company introduction updated',
        callout: {
          title: 'Introduce yourself',
          description:
            'Explain what makes your company stand out and why your services are excellent. For example, you could mention active years in your field, passions or specific skills or equipment.'
        },
        validations: {
          about: {
            required: 'Write a short presentation of your company'
          }
        }
      },
      language: {
        localeLabel: 'Dates / Numbers',
        successMessage: 'Language settings updated'
      },
      notifications: {
        viewed: 'Receive notification and email when client opens a document',
        acceptedSendEmail: 'Receive email when a client accepts a document',
        acceptedSendSms:
          'Receive a text message when a client accepts a document',
        getCopyAll: 'Receive a copy of all my teammates notifications',
        semiSigned:
          'Receive notification and email when a document is partially accepted',
        invitedProjectCreate:
          'Receive notifications when someone invites me to a project',
        invitedProjectSendEmail:
          'Receive emails when someone invites me to a project',
        successMessage: 'Notifications updated',
        clientEmailReplyToUser: {
          label: 'Use my email as a reply-to address for email',
          subLabel: 'Uses {{email}}'
        }
      },
      passwordModal: {
        current: 'Existing password',
        new: 'New password',
        successMessage: 'Password updated',
        title: 'Change password',
        validations: {
          passwordCurrent: {
            required: 'Enter existing password'
          },
          passwordNew: {
            required: 'Enter new password',
            minLength: 'Needs to be at least 6 characters long'
          }
        },
        dialog: {
          title: 'Unsaved changes',
          message: 'Are you sure you want to cancel?',
          cancel: 'Stay',
          submit: 'Yes, close'
        }
      },
      project: {
        priceInputDefault:
          'Set how you want to enter prices for your clients that are individuals',
        priceInputCompany:
          'Set how you want to enter prices for your company clients',
        defaultVatRate: 'Default VAT-rate on new articles',
        defaultLanguage: 'Default language',
        defaultCurrency: 'Default currency',
        defaultClientType: 'Add new clients as',
        nextProjectNumber: 'Next project number',
        nextOfferNumber: 'Next proposal number',
        nextContractNumber: 'Next contract number',
        nextAtaNumber: 'Next number for adherence agreement',
        successMessage: 'Project settings updated',
        houseWorkDeduction: {
          title: 'ROT/RUT-deduction',
          description:
            'If you work with deductible services you can active the calculator for these'
        },
        hidePrices: {
          title: 'Hide prices for services / products',
          description:
            'Active if you want to hide prices for some services / products when creating a document',
          allowHidePrice: 'Active hide price',
          hidePriceOnNewlyAddedArticles:
            'Hide prices for newly added services / products ',
          hideDocArticlePriceInput:
            'Hide prices as standard when adding services / products'
        },
        documentSenderSign: {
          title: 'Sign your own documents',
          description:
            'Set the default value if you want to add yourself as an signee to documents you send.',
          label: 'Sign as author'
        },
        autoExpireDoc: {
          defaultExpireDays: 'Default expiration days'
        },
        reminders: {
          off: 'Off',
          viewed: 'Client has not viewed document after',
          reminder: 'Client has not replied after',
          expire: 'When expiration date is within'
        },
        validations: {
          nextProjectNumber: {
            required: 'Add a number for next project',
            numeric: 'Only numbers allowed'
          },
          nextOfferNumber: {
            required: 'Add a number for next proposal',
            numeric: 'Only numbers allowed'
          },
          nextContractNumber: {
            required: 'Add next contract number',
            numeric: 'Only numbers allowed'
          },
          nextAtaNumber: {
            required: 'Add number for next adherence agreement',
            numeric: 'Only numbers allowed'
          }
        }
      },
      tab: {
        default: {
          selectSetting: 'Select a setting from the left menu.',
          inTheSpotlight: 'Featured',
          configureTheme: 'Setup theme',
          theme: {
            title: 'Theme builder',
            description: 'Create your own design in 3 simple steps.',
            actionText: 'Create a theme ->'
          }
        },
        photos: {
          title: 'My photos',
          calloutTitle: 'Showcase your past work',
          calloutDescription:
            'Add photos from your projects (before and after, team, offices, process or testimonials etc.)'
        },
        templates: {
          terms: {
            title: 'My terms',
            subTitle: 'Add your own terms',
            description:
              'If you have your own terms that you want to apply to this document you can set them here.',
            link: 'Configure'
          }
        },
        term: {
          form: {
            nameLabel: 'Term name',
            radio: {
              button: 'Form',
              tooltip: 'Remove PDF to change'
            },
            text: {
              label: 'Term text',
              placeholder: 'Write something'
            },
            dialog: {
              title: 'Are you sure you want to cancel?',
              message: 'You have not saved your changes for the ',
              cancel: 'Stay',
              submit: 'Leave page'
            },
            validations: {
              name: {
                required: 'Name is required',
                maxLength: 'Max. length is 256 characters'
              },
              textContent: {
                required: 'Add some content'
              }
            }
          }
        }
      },
      user: {
        changePasswordMainAccount:
          'In order to change password, please switch to your main account',
        callout: {
          title: 'Best practice',
          tip1: 'Make sure to smile.',
          tip2: 'Take the picture in daylight. No flash.',
          tip3: 'Keep camera at eye-level.',
          tip4: 'Choose a simple background.'
        },
        validations: {
          firstName: {
            required: 'Write your first name so your clients know who you are'
          },
          lastName: {
            required: 'Write your last name so your clients know who you are'
          },
          email: {
            required: 'Write your email',
            email: 'Your email needs to be in the format name@example.com'
          }
        }
      },
      social: {
        placeholderAddress: 'Type in your {{name}}-address',
        validWebsite:
          'Wrong format. Links can for example be :http://mypage.com',
        validSocialUrl:
          'Wrong format. Links can for example be :http://{{address}}/myaccount',
        successMessage: 'Your links was updated'
      },
      fortnox: {
        title: 'Fortnox',
        description:
          'Activate integration with Fortnox and you will be able to create invoice drafts from documents directly into Fortnox.',
        installedDescription:
          'You integration is installed. Go to a document to create a invoice draft to Fortnox',
        feature: {
          title: 'Create invoice drafts from documents',
          text: 'This integration allows you to export basic invoice data from documents directly into Fortnox.',
          more: 'Tell me more about Pro'
        },
        contactSupport: {
          description: 'Please contact support for help:',
          buttonText: 'Support',
          chatMessage:
            'Hello! Something went wrong with the integration of Fortnox and I could use some help.'
        }
      }
    },
    timeline: {
      modal: {
        invite: 'Invite member',
        name: 'Plan something',
        comment: 'Comment'
      }
    },
    notificationsPage: {
      markAll: 'Mark all as read',
      emptyText: 'You are up-to-date!'
    }
  },
  publicDocument: {
    pdf: {
      signing: 'Signing',
      signature: 'Signature',
      locationAndDate: 'Location and date',
      printedName: 'Printed Name',
      error: {
        title: 'Could not create PDF.',
        description: 'Please try again.'
      }
    },
    pdfVerification: {
      verifyAt:
        'This document has been verified by {{ brandName }}. The document is sealed with its electronic signatures. The authenticity of the document can be proved mathematically by a third party and by {{ brandName }}. For your convenience {{ brandName }} provides a service where you can verify the documents authenticity on: ',
      waitingForAnswer: 'Waiting for answer',
      recipient: {
        signedBankId: 'Signed with BankID by ',
        verifiedLogin: 'Verified with login authentication.'
      },
      signedAttachments: {
        title: 'Signed attachments'
      },
      inPerson: {
        signature: 'Signature',
        locationAndDate: 'Location and date',
        printedName: 'Printed Name'
      }
    },
    events: {
      sent: 'Document sent to {{to}}.',
      viewedName: '{{name}} viewed the document.',
      viewed: 'Has been viewed',
      accepted: 'Document signed by {{by}}.',
      denied: 'Document denied by {{by}}.',
      statusChanged: 'Document marked as {{status}}.',
      requestChange: 'Document manually marked as {{status}}.',
      expired: 'Document expired.'
    }
  },
  notFound: {
    title: 'Page could not be found',
    subTitle:
      'It seems like the link is broken or that the page has been removed. Try one of these links instead',
    homepage: 'Start page',
    createAccount: 'Create account',
    chat: 'Chat with us',
    chatMessage: "Hi! I'm not able to access a page. Can you help me?"
  },
  login: {
    title: 'Welcome back',
    forgottenLink: 'Forgot password?',
    userIdPlaceholder: 'UserId',
    missingText: "Don't have an account?",
    createLink: 'Create account',
    validations: {
      email: {
        required: 'Email missing',
        email: 'Invalid format, need to be: name@example.se'
      },
      password: {
        required: 'Enter password'
      }
    }
  },
  forgottenPassword: {
    title: 'Forgot your password?',
    description:
      'Enter your email and we will send a reset link to your inbox.',
    resetButton: 'Reset password',
    hintText: 'Did you remember the password?',
    didRequest: {
      title: 'Email sent!',
      description:
        'If a user exists for {{email}}, you will receive a reset link in your inbox.'
    }
  },
  newPassword: {
    title: 'Choose new password',
    placeholder: 'Enter new password'
  },
  register: {
    title: 'Create an account and win more deals',
    hintText: 'Already have an account?',
    demo: {
      title: 'Register demo account',
      subTitle: 'Create a demo account for a lead',
      button: 'Create account',
      companyPlaceholder: 'Your company name',
      emailPlaceholder: 'Enter your email',
      cellphonePlaceholder: '(Optional) Enter a phone number',
      smsLabel: 'Send link by SMS?',
      manualLabel: 'Manual onboarding?',
      employee: {
        label: "Who's opening the demo account",
        placeholder: "Choose who's opening the demo account"
      },
      greeting: {
        title: 'Create winning proposals in no time',
        subTitle: "With {{brand,capitalize}} it's easy to multitask."
      }
    },
    existing: {
      title: 'Join {{companyName}}',
      subTitle:
        'You have been invited to join their {{brand,capitalize}}-team.',
      subTitleByName:
        '{{invitedByName}} have sent an invitation to join their {{brand,capitalize}}-team.',
      passwordPlaceholder: 'Choose password',
      terms: {
        text: 'I accept the',
        link: 'terms & conditions'
      }
    },
    deprecatedBrand: {
      text: 'It is no longer possible to sign up here. Go to {{url}} to create a new account.',
      link: 'Go to {{url}}'
    }
  },
  unsubscribe: {
    title: 'Unsubscribe',
    description:
      'If you unsubscribe you will <strong>not</strong> be able to receive documents from companies using {{brand,capitalize}}, or our own communications.',
    placeholder: 'Enter your email',
    button: 'Unsubscribe for email',
    spinnerText: 'Unsubscribing',
    supportLinkTitle: 'Did you unsubscribe by mistake?',
    success: {
      title: 'You are now unsubscribed',
      description:
        'You will no longer receive emails from us. Did you unsubscribe by mistake?.',
      link: 'Contact support.'
    },
    error: {
      title: 'Something went wrong',
      description1:
        "Oops! Something went wrong. Unfortunately we couldn't unsubscribe you from email distributions, please",
      description2: 'and we will make sure to fix it.',
      contactUs: 'contact us',
      theSupport: 'the support',
      link1: 'Contact us on',
      link2: 'for help.'
    },
    tokenError: {
      title: 'Something went wrong',
      description:
        'We could not automatically verify your unsubscribe. Would you like to manually unsubscribe with your email instead?',
      button: 'Unsubscribe with email'
    },
    validations: {
      email: {
        required: 'Enter your email',
        email: 'Your email needs to be on the format: name@example.com'
      }
    }
  }
}
