export default {
  account: {
    app: {
      overlayAppLoader: {
        subTitle: 'Your friends at {{brand,capitalize}}',
        quote1: 'Establishing secure connection.',
        quote2: 'Drink a lot of coffee if you can.',
        quote3: 'Make sure you get enough sleep.',
        quote4: 'Calibrating satellite positions.',
        quote5: 'Connecting to the main frame...',
        quote6: 'Have a great day!'
      },
      overlayNetworkError: {
        title: 'Oops! An error occurred',
        subTitle: 'Try reloading the page or contact us via chat',
        networkError: {
          title: 'No internet connection',
          subTitle: 'Page is paused until you reconnect to the internet.'
        }
      }
    },
    document: {
      calloutDeniedReasons: {
        title: "Your client did not accept, but there's still hope!",
        description:
          "Your client gave the following reasons why they didn't accept the document, {{documentName}}:",
        commentFromClient: 'Client comments',
        button: 'Edit & resend'
      },
      invoice: {
        quickOptions: 'Quick options',
        scheduledSend: 'To be sent',
        empty: "You haven't created any documents.",
        actions: {
          showProject: 'Go to project',
          edit: 'Edit',
          sendReminder: 'Send reminder',
          sendReminderTooltip: 'Change document status to send again.',
          duplicate: 'Duplicate',
          invoice: 'Invoice',
          invoiceTooltip: 'Mark document as accepted first.',
          print: 'Print',
          upgradeSignature: 'Upgrade to contract',
          remove: 'Delete',
          removeDialog: {
            title: 'Delete document',
            message: 'Are you sure you want to delete the document?',
            cancel: 'Cancel',
            submit: 'Delete document'
          }
        }
      },
      statusDropdown: {
        tooltip: "You don't have permission to change status"
      },
      articleItem: {
        autoCompletePlaceholder: 'E.g. services & products',
        hidePriceLabel: 'Hide price for client',
        priceTypeShort: {
          approx: 'Approx. price',
          markup: 'Markup',
          openAcc: 'Open Acc.'
        },
        validations: {
          internalArticleName: {
            maxLength: 'Max. 256 characters are allowed',
            required: 'Name is required if you set a price'
          },
          internalQuantity: {
            minValue: 'Minimum quantity is 1',
            required: 'Add quantity amount'
          }
        }
      }
    },
    documentForm: {
      misc: {
        addSample: "Click '{{thing}}' to try this sample."
      },
      contentItems: {
        header: {
          redOverlay: {
            previewDocumentName:
              '<tspan x="113.008" y="133.227">Sales Proposal</tspan>',
            previewPostText:
              '<tspan x="174.453" y="228.818">Prepared for Sue Doe&#10;</tspan><tspan x="177.878" y="235.818">From James Smith</tspan>',
            documentName: '<p data-typography="title">Sales Proposal</p>',
            postText:
              '<p><span>Prepared for {{clients.0.name}}</span></p><p><span>By {{companyUser.fullName}}</span></p>'
          },
          greenLeaf: {
            previewDocumentName:
              '<tspan x="174" y="132.273">Sales Proposal</tspan>',
            previewPostText:
              '<tspan x="174" y="216.545">Prepared for Sue Doe&#10;</tspan><tspan x="174" y="226.545">From James Smith</tspan>',
            documentName: '<p data-typography="title">Sales Proposal</p>',
            postText:
              '<p><span>Prepared for {{clients.0.name}}</span></p><p><span>By {{companyUser.fullName}}</span></p>'
          },
          darkGreenSkyscraper: {
            previewDocumentName:
              '<tspan x="27" y="80.2727">Sales Proposal</tspan>',
            previewPostText:
              '<tspan x="27" y="114.682">Prepared for Sue Doe&#10;</tspan><tspan x="27" y="123.682">From James Smith</tspan>',
            documentName: '<p data-typography="title">Sales Proposal</p>',
            postText:
              '<p><span>Prepared for {{clients.0.name}}</span></p><p><span>By {{companyUser.fullName}}</span></p>'
          },
          purpleGossip: {
            previewDocumentName:
              '<tspan x="45" y="132.273">Sales Proposal</tspan>',
            previewPostText:
              '<tspan x="45" y="216.545">Prepared for Sue Doe&#10;</tspan><tspan x="45" y="226.545">From James Smith</tspan>',
            documentName: '<p data-typography="title">Sales Proposal</p>',
            postText:
              '<p><span>Prepared for {{clients.0.name}}</span></p><p><span>By {{companyUser.fullName}}</span></p>'
          },
          mauveComputer: {
            previewDocumentName:
              '<tspan x="27" y="176.273">Sales Proposal</tspan>',
            previewPostText:
              '<tspan x="27" y="210.682">Prepared for Sue Doe&#10;</tspan><tspan x="27" y="219.682">From James Smith</tspan>',
            documentName: '<p data-typography="title">Sales Proposal</p>',
            postText:
              '<p><span style="color: #FFC1E6">Prepared for {{clients.0.name}}</span></p><p><span style="color: #FFC1E6">By {{companyUser.fullName}}</span></p>'
          }
        },
        coverBlock: {
          violetTestimonial: {
            previewTitle: '<tspan x="89" y="163.5"> - Client Name</tspan>',
            previewText:
              '<tspan x="89" y="89.5">Include a testimonial from a happy client here. </tspan><tspan x="89" y="108.5">The key is to have your client talk about why </tspan><tspan x="89" y="127.5">they enjoyed working with you.&#10;</tspan>',
            text: `<blockquote>
              <p>
                <span style="color: hsla(297,93.75%,87.45%,1);">
                  Include a testimonial from a happy client here. The key is to have your client talk about why they enjoyed working with you.
                </span>
              </p>
              <p>
                <span style="color: hsla(297,93.75%,87.45%,1);"><br>- Client Name</span>
              </p>
            </blockquote>`
          },
          ourWorkCouch: {
            previewText: '<tspan x="109.102" y="129.545">Our work</tspan>',
            text: '<p data-typography="title" style="text-align: center;">Our work</p>'
          },
          flyoverSkyscraper: {
            text: '<p data-typography="title" style="text-align: center;"><span style="color: hsla(0,0%,100%,1);">Our Work</span></p>'
          },
          qualityGradient: {
            previewTitle: '<tspan x="139.271" y="122.364">Quality</tspan>',
            previewText:
              '<tspan x="125.29" y="143.545">We are commited to deliver experiences that </tspan><tspan x="155.877" y="151.545">exceed your expectations.</tspan>',
            text: '<p data-typography="title" style="text-align: center;"><span style="color: hsla(0,0%,100%,1);">Quality</span></p>'
          },
          palmLeafProcess: {
            previewTitle: '<tspan x="88" y="103.636">Process</tspan>',
            previewText:
              '<tspan x="88" y="121.909">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </tspan><tspan x="88" y="131.909">Nam hendrerit nisi sed sollicitudin pellentesque. Nunc </tspan><tspan x="88" y="141.909">posuere purus rhoncus pulvinar aliquam. Ut aliquet </tspan><tspan x="88" y="151.909">tristique nisl vitae volutpat. Nulla aliquet porttitor </tspan><tspan x="88" y="161.909">venenatis. </tspan>',
            text: `
              <p data-typography="title">
                <span style="color: hsla(41.16,100%,76.57%,1);">Process</span>
              </p>
              <p>
                <span style="color: hsla(41.16,100%,76.57%,1);">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique nisl vitae volutpat. Nulla aliquet porttitor venenatis.
                </span>
              </p>
            `
          }
        }
      },
      attachments: {
        fileNamePlaceholder: 'File name'
      },
      clients: {
        title: "Who's your client?", // todo: need new wording?
        subTitle: 'Add a recipient.',
        errorMessage: 'Add a recipient.',
        search: {
          label: 'A recipient I already have',
          placeholder: 'An existing recipient',
          empty: 'No results found for'
        },
        add: {
          extra: 'Add more recipients',
          new: 'Add new recipient',
          me: 'Add me!',
          fake: 'Add sample recipient'
        }
      },
      extraWork: {
        title: 'Changes & extra work'
      },
      settings: {
        backgroundImage: 'Background image',
        backgroundFilter: 'Background filter',
        priceSummaryBackground: 'Price summary',
        headingColor: 'Heading lines color'
      },
      titleAndText: {
        title: 'Title',
        text: 'Type something',
        error: 'Add some text or delete this block.'
      }
    },
    notification: {
      setAllRead: 'Mark all as read',
      upToDate: 'You are up-to date!',
      showAll: 'Show all notifications',
      showEarlier: 'Show earlier notifications',
      markAsRead: 'Mark as read'
    },
    subscription: {
      callout: {
        title: 'Your plan',
        upgradeButton: 'Get'
      },
      premiumStripe: {
        title: 'Go Pro ->',
        titleEdit: 'Edit plan',
        subTitle: 'Upgrade your account to unlock more benefits.',
        subTitleEdit: 'Adjust your payment plan.',
        selectAccountType: 'Select plan',
        paymentPlan: 'Select period',
        paymentMethod: 'How do you want to pay?',
        paymentQuantity: 'Number of licenses',
        paymentQuantityTooltip:
          'Number of members that can create & send documents.',
        paymentInformation: 'Enter payment information',
        invoiceRestrictedPlan:
          'Invoice is only available for yearly payment plans.',
        submitButton: 'Upgrade to {{plan,capitalize}}',
        errorMessage: 'An error has occurred, please try again.',
        quantityDisabled: 'Switch to <strong>Pro</strong> to add more editors.',
        existingCard: 'Charges will be billed to the card ending in {{last4}}.',
        changeCard: 'Change card',
        changeEmail: 'Change email',
        plans: {
          starter: {
            name: 'Starter',
            text: `
              <div>Single user only.</div>
              <div>Send 5 documents per month.</div>
              <div>BankID-signatures</div>
            `
          },
          premium: {
            name: 'Pro',
            text: `
              <div>Extended eSignatures.</div>
              <div>Statistics.</div>
              <div>Integrations.</div>
            `
          }
        },
        callout: {
          title: 'Upgrade to {{brand,capitalize}} Pro',
          description:
            'Bring your business to the next level. Send unlimited amount of documents and invoices.'
        },
        list: {
          securePayment: 'Secure card payment.',
          satisfiedCustomer: '30 days satisfied customer guarantee.'
        },
        success: {
          title: 'Congratulations!',
          subTitle: 'Your account have been upgraded.',
          subTitleEdit: 'Your plan has been updated.',
          closeButton: 'Continue where you were'
        },
        promotion: {
          addPromo: 'Promo code?',
          placeholder: 'Promotion code',
          submit: 'OK'
        },
        validations: {
          organisationNumber: {
            isValidOrgNoWrapper: 'Enter valid organization number'
          },
          email: {
            required: 'Email missing',
            email: 'Invalid format, need to be: name@example.se'
          },
          phone: {
            required: 'Enter phone number'
          },
          quantity: {
            required: 'Enter number of licenses',
            minValue: 'Add a positive number'
          }
        }
      }
    },
    themeBuilder: {
      callout: {
        title: 'Create your own document design',
        description:
          'Use our simple theme builder to create a beautiful design you can use on all your documents.',
        openText: 'Open Theme Builder',
        hideText: 'Hide message'
      },
      preview: {
        colorsTitle: 'A big title',
        colorsSubTitle: 'Smaller heading',
        fontsTitle: 'We are a modern digital agency',
        cover1: 'About us',
        cover2: 'Process',
        header1: 'Sales Proposal'
      },
      complete: {
        loading: {
          title: 'Creating your theme',
          text: {
            buildingDocument: 'Building up document structure...',
            designingElements: 'Adding style & theme...',
            addingText: 'Adding sample text...',
            done: 'Finished'
          }
        },
        success: {
          title: 'Your document theme is ready',
          text: 'Do you want to preview and create a document with your newly created theme?',
          createButton: 'Create a doc with my theme',
          continueButton: 'Continue where you were'
        }
      }
    },
    menu: {
      switchAccounts: 'Switch accounts',
      misc: 'More',
      goPro: 'Go Pro',
      settings: {
        styling: 'Manage appearance',
        chat: 'Chat with us',
        addName: 'Add name'
      }
    },
    search: {
      noResults: 'No results found',
      title: 'Search for something',
      subTitle: 'Project, clients etc.',
      filterLabelAll: 'All'
    },
    autoSaveNotes: {
      placeholder:
        'Write something you want to remember. \nNotes are only visible to you & your team.',
      updated: {
        posting: 'Saving...',
        recent: 'Just updated',
        ago: 'Updated {{time}}'
      }
    },
    connectedUsersModal: {
      title: 'My accounts',
      loggedInTooltip: 'Currently logged in as',
      button: 'Switch account',
      successMessage: 'Main account have been updated',
      default: {
        tooltip: 'Account you use most often',
        text: 'Main account'
      }
    },
    oldBrowserWarning: {
      title: 'Your browser is out of date',
      description:
        'For {{brand,capitalize}} to function properly and because of safety reasons we recommend that you <strong>update your browser</strong>or use <strong>Google Chrome.</strong>'
    },
    navbarSearch: {
      tooltip: 'Search for something'
    }
  },
  document: {
    myPagesDrawer: {
      pendingAnswer: "Haven't left an answer yet."
    },
    summaryTop: {
      titleMissing: 'Untitled',
      sentAtPlaceholder: 'Set when sent'
    },
    summaryBottom: {
      chooseFrom: 'Choose from selectable options',
      forDetails: 'for details',
      noOfOptions: '{{count}}',
      option: 'option',
      option_other: 'options',
      reverseVat: 'VAT {{- amount}}. Reverse tax liability applies'
    },
    coverBlock: {
      defaultText: 'An interesting title.'
    },
    videoPlayer: {
      subTitle: 'Paste a YouTube, Vimeo, or Wistia video link.',
      placeholder: 'Paste video link',
      validations: {
        url: {
          isValid: 'No supported video found'
        }
      }
    },
    chapters: {
      header: {
        postText:
          '<p><span>Prepared for {{clients.0.name}}</span></p><p><span>By {{companyUser.fullName}}</span></p>'
      },
      multiGroupPricing: {
        title: 'Price summary'
      },
      packageGroup: {
        title: 'Scope of Work'
      },
      parties: {
        title: 'Parties',
        fTax: 'Certified for F-tax',
        allRiskAndLiability: 'Has all risk- and liability insurance',
        placeholder: {
          title: 'Add a client',
          subTitle: "You haven't added a recipient."
        },
        missingSender: {
          title: 'Add contact information',
          description: 'Add your company information to the document.'
        }
      },
      workPackage: {
        title: 'Scope of work'
      },
      warningInfo: {
        title: 'Dissuasive'
      },
      estimatedTime: {
        title: 'Estimated time'
      },
      extraWorkTimeImpact: {
        title: 'Time impact additional work'
      },
      extraWorkConstructionPricingList: {
        title: 'Amendments and additional work'
      },
      paymentTerms: {
        title: 'Payment'
      },
      misc: {
        title: 'Misc'
      },
      subChapters: {
        clients: {
          title: 'Clients'
        },
        sender: {
          title: 'Contractor'
        },
        workAddress: {
          title: 'Address for proposed work'
        },
        description: {
          title: 'Description'
        },
        travelCosts: {
          title: 'Travel costs'
        },
        attachments: {
          title: 'Attachments'
        },
        notIncluded: {
          title: 'Work & material not included'
        },
        warningInfo: {
          title: 'Work requested by contractor despite dissuasion'
        },
        estimatedTimeToComplete: {
          title: 'Estimated time to complete'
        },
        estimatedWorkStartAndEndAt: {
          title: 'Duration dates'
        },
        extraWorkConstructionPricingList: {
          title: 'In the event of additional work'
        },
        extraWorkTravelCosts: {
          title: 'Travel costs for additional work'
        },
        paymentTerms: {
          title: 'Payment terms'
        },
        miscAgreement: {
          title: 'Additional agreements'
        },
        docIsBinding: {
          title: 'Contractor claims'
        },
        extraWorkTimeImpact: {
          title: 'Time impact'
        }
      }
    },
    printButton: {
      tooltip: 'Save or send the document first'
    },
    addonDocumentCallout:
      'This is an additional- or addendum agreement to the document',
    acceptModal: {
      title: 'You have approved the document!',
      description:
        'Thanks for your answer! We have informed {{- senderName}} that you have approved the document.',
      propDesTitle: 'Hurry up your ROT deduction',
      propDesDescription:
        'Make it easier for yourself to receive your ROT deduction. Enter your property designation.',
      propDesButton: 'Enter property designation',
      backToDocument: 'Back to document',
      signature: {
        title: 'Sign to Accept',
        subTitle: 'Choose sign style',
        terms:
          'By signing the document I agree that the electronic signature used is an representation of my handwritten signature - and is as valid.',
        action: 'Sign & accept',
        draw: {
          tab: 'Draw',
          canvasLabel: 'Draw signature'
        },
        text: {
          tab: 'Type',
          nameLabel: 'Full name',
          namePlaceholder: 'Type in your name',
          canvasLabel: 'Preview'
        }
      },
      registerCallout: {
        text: 'Do you also want to send beautiful documents?',
        action: 'Sign up for free'
      }
    },
    propertyDesignationModal: {
      title: 'Enter property designation',
      subTitle:
        'To make it easier for yourself to receive your ROT deduction, {{- senderName}} needs your property designation.',
      howToFind: 'How do I find my property designation?',
      goto1: 'Go to',
      goto2: 'Sweden national land survey',
      goto3:
        ', and under My pages you can find your property designation. You can login with BankID.',
      whereTitle: 'Where will the work take place?',
      house: 'House',
      realEstate: 'Real estate',
      condominium: 'Condominium',
      apartment: 'Apartment',
      propertyDesignation: 'Property designation',
      propertyDesignationPlaceholder: 'Your property designation',
      condominiumId: 'Condominium identification number',
      condominiumOrgNo: 'Condominium organization number',
      condominiumIdPlaceholder: '4 digits',
      condominiumOrgNoPlaceholder: '10 digits',
      savedTitle: 'Your property designation has been saved!',
      savedSubTitle:
        'Thanks for submitting the information. We will inform {{- senderName}} they can now prepare the deduction.',
      validations: {
        condominiumId: {
          required: 'Enter your condominium identification number',
          minLength: 'Needs to be 4 digits',
          maxLength: 'Needs to be 4 digits'
        },
        condominiumOrgNo: {
          required: 'Enter your condominium organization number'
        },
        realEstateId: {
          required: 'Enter your property designation'
        }
      }
    }
  },
  forms: {
    article: {
      namePlaceholder: 'Product name',
      pricePlaceholder: 'Price per unit',
      artNoPlaceholder: 'Optional',
      dialog: {
        title: 'Unsaved changes',
        message: 'Are you sure you want to cancel?'
      },
      validations: {
        name: {
          required: 'Give the product a name',
          maxLength: 'Name can maximum be 256 characters'
        },
        price: {
          decimal: 'Price must be a number',
          minValue: 'Add a positive number'
        }
      }
    }
  },
  signup: {
    form: {
      consent: {
        label: 'I accept the',
        link: 'terms & conditions'
      },
      submit: {
        text: 'Create an account',
        free: "it's free"
      },
      verify: {
        title: 'Verify your email',
        description:
          'Click on the link in your email to create an additional user for {{email}}.'
      },
      validations: {
        companyName: {
          required: 'Enter company name',
          maxLengthValue: 'Max {{maxLength}} characters'
        },
        email: {
          required: 'Email missing',
          email: 'Invalid format, need to be: name@example.se'
        },
        password: {
          required: 'Enter password',
          minLength: 'Minimum 6 characters'
        }
      },
      existingAccount: {
        title: 'Existing user',
        message:
          'A user with this email already exists. Would you still like to create a new account?',
        reset: 'Reset password',
        submit: 'Create new account'
      }
    }
  },
  base: {
    avatar: {
      tooltip: 'Unknown user'
    },
    uploadList: {
      dropArea: 'Drag & drop files or click here'
    },
    table: {
      empty: 'Nothing to see here.'
    },
    textEditorTemplates: {
      emptyTitle: 'You haven’t saved any templates',
      emptySubTitle: 'Write a text above and click save',
      button: 'Save as template'
    }
  },
  infiniteLoader: {
    noMore: 'No more results',
    noResults: 'No more results'
  },
  cling: {
    message: {
      tip: 'Tip'
    }
  }
}
