import config from '@cling/config'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  yes: 'yes',
  no: 'no',
  thanks: 'thanks',
  times: 'time',
  times_other: 'times',
  second: 'second',
  second_other: 'seconds',
  minute: 'minute',
  minute_other: 'minutes',
  hour: 'hour',
  hour_other: 'hours',
  day: 'day',
  day_other: 'days',
  week: 'week',
  week_other: 'weeks',
  weeks: 'week',
  weeks_other: 'weeks',
  month: 'month',
  month_other: 'months',
  months: 'month',
  months_other: 'months',
  quarter: 'quarter',
  quarter_other: 'quarters',
  year: 'year',
  year_other: 'years',
  years: 'year',
  years_other: 'years',
  date: 'date',
  date_other: 'dates',
  time: 'time',
  calendar: 'calendar',
  calendar_other: 'calendars',
  weekend: 'weekend',
  weekend_other: 'weekends',
  today: 'today',
  project: 'project',
  project_other: 'projects',
  projectNo: 'project-no',
  projectNumber: 'project number',
  projectName: 'project name',
  client: 'client',
  client_other: 'clients',
  member: 'member',
  member_other: 'members',
  user: 'user',
  user_other: 'users',
  article: 'product',
  article_other: 'products',
  artNo: 'ID',
  articleNo: 'ID',
  articleNumber: 'product number',
  package: 'package',
  package_other: 'packages',
  group: 'group',
  group_other: 'groups',
  unit: 'unit',
  unit_other: 'units',
  task: 'task',
  task_other: 'tasks',
  template: 'template',
  template_other: 'templates',
  snippet: 'snippet',
  snippet_other: 'snippets',
  invoice: 'invoice',
  invoice_other: 'invoices',
  invoiceDo: 'invoice',
  invoiced: 'invoiced',
  card: 'card',
  card_other: 'cards',
  attest: 'attest',
  attested: 'attested',
  document: 'Document',
  document_other: 'documents',
  theDocument: 'the document',
  documentName: 'Document name',
  offer: 'proposal',
  proposal: 'proposal',
  proposal_other: 'proposals',
  sampleProposal: 'sample', // Intentional choice to not include "proposal". String becomes too long.
  contract: 'contract',
  contract_other: 'contracts',
  ata: 'adherence agreement',
  addonDocument: 'Adherence agreement',
  ataConstruction: 'Adherence agreement',
  offerThe: 'The proposal',
  contractThe: 'The contract',
  ataThe: 'Adherence agreement',
  ataConstructionThe: 'Adherence agreement',
  shorten: 'Shorten',
  extend: 'Extend',
  sort: 'sort',
  exVat: 'ex. {{-thing}}',
  incVat: 'incl. {{-thing}}',
  accept: 'accept',
  approve: 'Approve',
  approved: 'approved',
  deny: 'Decline',
  denied: 'declined',
  sign: 'Sign',
  signed: 'signed',
  pay: 'Pay',
  void: 'Void',
  discount: 'discount',
  print: 'Print{{thing,inlineSpace}}',
  info: 'info',
  free: 'Free',
  premium: 'Premium',
  yearly: 'yearly',
  monthly: 'monthly',
  approx: 'approx.',
  approximately: 'Approximately',
  replyBy: 'Reply by',
  respondWithin: 'Respond within',
  belongsTo: 'belongs to',
  name: 'name',
  nameMissing: 'Missing name',
  untitled: 'Untitled',
  firstName: 'first name',
  lastName: 'last name',
  companyName: 'company name',
  company: 'company',
  theCompany: 'the company',
  individual: 'individual',
  sender: 'from',
  receiver: 'to',
  contractor: 'contractor',
  theContractor: 'the contractor',
  person: 'person',
  person_other: 'persons',
  contact: 'contact',
  contact_other: 'contacts',
  recipient: 'recipient',
  recipient_other: 'recipients',
  participant: 'participant',
  participant_other: 'participants',
  signature: 'signature',
  signature_other: 'signatures',
  signingParticipant: 'Signing participant',
  signingParticipant_other: 'Signing participants',
  email: 'email',
  email_other: 'emails',
  sms: 'SMS',
  phone: 'phone',
  phone_other: 'phones',
  cellphone: 'cellphone',
  cellphone_other: 'cellphones',
  address: 'address',
  address_other: 'addresses',
  addressWork: 'work address',
  image: 'image',
  image_other: 'images',
  file: 'file',
  file_other: 'files',
  photo: 'photo',
  photo_other: 'photos',
  video: 'video',
  video_other: 'videos',
  link: 'link',
  background: 'background',
  backgroundColor: 'background color',
  logo: 'logo',
  size: 'size',
  album: 'album',
  album_other: 'albums',
  presentation: 'presentation',
  presentation_other: 'presentations',
  theme: 'theme',
  theme_other: 'themes',
  appearance: 'appearance',
  appearance_other: 'appearances',
  customize: 'Customize',
  language: 'language',
  language_other: 'languages',
  password: 'password',
  password_other: 'passwords',
  text: 'text',
  text_other: 'texts',
  field: 'field',
  field_other: 'fields',
  list: 'list',
  list_other: 'lists',
  textAlign: 'text align',
  textColor: 'text color',
  city: 'city',
  city_other: 'cities',
  position: 'position',
  position_other: 'positions',
  phoneNumber: 'phone number',
  cellphoneNumber: 'cellphone number',
  zip: 'zip',
  zipCode: 'zip code',
  orgNo: 'Company registration number',
  orgNoShort: 'Co. registration number',
  socialNo: 'social security number',
  socialNoShort: 'ssn',
  aboutUs: 'About us',
  new: 'New{{thing,inlineSpace}}',
  new_tWord: 'New{{thing,inlineSpace}}',
  visit: 'Visit{{thing,inlineSpace}}',
  done: 'done',
  finished: 'done',
  verify: 'verify{{thing,inlineSpace}}',
  verified: 'verified',
  plan: 'Plan',
  planned: 'planned',
  save: 'Save{{thing,inlineSpace}}',
  saveAs: 'Save as{{thing,inlineSpace}}',
  saving: 'saving',
  savedThing: '{{thing,capitalize}} saved',
  goTo: 'Go to',
  previous: 'previous',
  next: 'next',
  continue: 'Continue',
  cancel: 'Cancel',
  preview: 'Preview{{thing,inlineSpace}}',
  view: 'View{{thing,inlineSpace}}',
  viewed: 'viewed',
  viewedXTimes: 'Viewed {{n}} times',
  total: 'total',
  edit: 'Edit{{thing,inlineSpace}}',
  enterThing: 'Enter{{thing,inlineSpace}}',
  change: 'Change{{thing,inlineSpace}}',
  move: 'Move{{thing,inlineSpace}}',
  remove: 'Remove{{thing,inlineSpace}}',
  removedThing: 'Removed {{thing,capitalize}}',
  up: 'up',
  down: 'down',
  left: 'left',
  right: 'right',
  center: 'center{{thing,inlineSpace}}',
  align: 'Align{{thing,inlineSpace}}',
  work: 'work',
  use: 'Use{{thing,inlineSpace}}',
  used: 'used',
  using: 'using',
  buy: 'Buy{{thing,inlineSpace}}',
  purchase: 'Purchase{{thing,inlineSpace}}',
  purchased: 'Purchased{{thing,inlineSpace}}',
  handle: 'Handle',
  adjust: 'Adjust',
  missing: 'Missing{{thing,inlineSpace}}',
  start: 'Start',
  stop: 'Stop',
  reset: 'Reset',
  end: 'end',
  overview: 'overview',
  signup: 'Sign up',
  open: 'Open',
  openNewWindow: 'Open in new window',
  openNewTab: 'Open in new tab',
  close: 'Close',
  goBack: 'Go back',
  undo: 'Undo',
  share: 'Share{{thing,inlineSpace}}',
  search: 'Search',
  result: 'result',
  result_other: 'results',
  noResults: 'No results',
  chat: 'chat',
  optional: 'optional',
  select: 'Select{{thing,inlineSpace}}',
  selected: 'selected',
  add: 'Add{{thing,inlineSpace}}',
  insert: 'Insert{{thing,inlineSpace}}',
  create: 'Create{{thing,inlineSpace}}',
  created: 'Created',
  createdThing: '{{thing,capitalize}} created',
  see: 'see',
  show: 'Show{{thing,inlineSpace}}',
  hide: 'Hide',
  empty: 'Empty{{thing,inlineSpace}}',
  more: 'more',
  less: 'less',
  and: 'and',
  or: 'or',
  with: 'with',
  to: 'to',
  from: 'from',
  via: 'via',
  again: 'again',
  other: 'other',
  none: 'none',
  authenticate: 'Authenticate',
  login: 'Login',
  logout: 'Logout',
  loggingOut: 'Logging out',
  browse: 'browse',
  download: 'Download{{thing,inlineSpace}}',
  downloading: 'downloading',
  upload: 'Upload{{thing,inlineSpace}}',
  uploading: 'uploading',
  update: 'Update{{thing,inlineSpace}}',
  updated: 'updated',
  updatedThing: '{{thing,capitalize}} updated',
  invite: 'Invite{{thing,inlineSpace}}',
  invitation: 'invitation',
  send: 'Send{{thing,inlineSpace}}',
  sent: 'Sent{{thing,inlineSpace}}',
  sentThing: '{{thing,capitalize}} sent',
  sentThingTo: '{{thing,capitalize}} sent to {{recipient}}',
  sentByName: 'Sent by {{name}}',
  reviewSend: 'Review & Send',
  delivery: 'delivery',
  scheduled: 'scheduled',
  scheduledDelivery: 'Scheduled delivery',
  reference: 'reference',
  reference_other: 'references',
  certificate: 'certificate',
  certificate_other: 'certificates',
  attachment: 'attachment',
  attachment_other: 'attachments',
  transaction: 'transaction',
  transaction_other: 'transactions',
  receipt: 'receipt',
  receipt_other: 'receipts',
  verification: 'verification',
  verification_other: 'verifications',
  integration: 'integration',
  integration_other: 'integrations',
  checklist: 'checklist',
  checklist_other: 'checklists',
  description: 'description',
  description_other: 'descriptions',
  detail: 'detail',
  detail_other: 'details',
  step: 'step',
  step_other: 'steps',
  guide: 'guide',
  guide_other: 'guides',
  color: 'color',
  color_other: 'colors',
  gradient: 'gradient',
  gradient_other: 'gradients',
  logotype: 'logotype',
  logotype_other: 'logotypes',
  avatar: 'profile photo',
  avatar_other: 'avatars',
  note: 'note',
  note_other: 'notes',
  website: 'website',
  website_other: 'websites',
  message: 'message',
  message_other: 'messages',
  review: 'review',
  review_other: 'reviews',
  greeting: 'greeting',
  greeting_other: 'greetings',
  setting: 'setting',
  setting_other: 'settings',
  settings: 'settings',
  option: 'option',
  option_other: 'options',
  value: 'value',
  value_other: 'values',
  amount: 'amount',
  quantity: 'quantity',
  nothing: 'nothing',
  reminder: 'reminder',
  reminder_other: 'reminders',
  schedule: 'Schedule{{thing,inlineSpace}}',
  demo: 'demo',
  version: 'version',
  version_other: 'versions',
  filter: 'filter',
  filter_other: 'filters',
  blur: 'blur',
  archive: 'archive',
  statistics: 'statistics',
  bookkeeping: 'bookkeeping',
  winProfile: 'WinProfile',
  pieceSuffix: '',
  disabled: 'disabled',
  block: 'block',
  block_other: 'blocks',
  copy: 'Copy{{thing,inlineSpace}}',
  copyMessage: 'Copied to clipboard',
  category: 'category',
  setup: 'setup',
  install: 'Install',
  installing: 'Installing',
  uninstall: 'Uninstall',
  uninstalling: 'Uninstalling',
  explore: 'Explore{{thing,inlineSpace}}',
  table: 'table',
  reload: 'Reload',
  rename: 'Rename',
  library: 'Library',
  statistics_short: 'Stats',
  browser: 'Browser',
  device: 'Device',
  price: {
    priceType: 'price type',
    pricing: 'pricing',
    price: 'price',
    price_other: 'prices',
    proposal: 'Package options',
    fixed: 'Fixed price',
    total: 'Total price',
    approx: 'Approximate price',
    openAcc: 'Open account',
    openAccPrice: 'Open account',
    openAccMaxPrice: 'Open account with max price',
    markup: 'Markup',
    maxPrice: 'max price',
    summary: 'summary',
    net: 'Net total'
  },
  currency: {
    currency: 'currency',
    SEK: 'SEK'
  },
  numberPrefix: {
    thousand: 't',
    million: 'm',
    billion: 'b'
  },
  deduction: {
    deduction: 'deduction',
    type: '{{type}}-deduction',
    manualType: 'Custom {{type}}-deduction',
    after: 'after deduction',
    afterType: 'after {{type}}-deduction',
    rot: 'ROT',
    rut: 'RUT',
    greenRot15: 'Green ROT',
    greenRot20: 'Green ROT',
    greenRot50: 'Green ROT',
    // percentage context
    rot_percentage: 'ROT',
    rut_percentage: 'RUT',
    greenRot15_percentage: 'Green ROT 15%',
    greenRot20_percentage: 'Green ROT 20%',
    greenRot50_percentage: 'Green ROT 50%'
  },
  series: {
    default: 'Document',
    document: 'Document',
    proposal: 'Proposal',
    contract: 'Contract',
    ata: 'Adherence agreement'
  },
  documentRole: {
    title: 'Document role',
    signee: {
      label: 'Signee',
      description: 'Needs to sign'
    },
    recipient: {
      label: 'Recipient',
      description: 'Receives a copy'
    },
    sender: {
      label: 'Sender',
      description: 'Sender'
    },
    approver: {
      label: 'Approver',
      description: 'Needs to approve'
    }
  },
  signMethod: {
    title: 'Sign method',
    bankId: 'BankId',
    signature: 'Signature',
    button: 'Button',
    login: 'Login',
    inPerson: 'In person'
  },
  status: {
    status: 'Status',
    sent: 'Sent',
    completed: 'Completed',
    draft: 'Draft',
    attested: 'Attested',
    reminderSent: 'Reminder',
    salesRequest: 'Factoring',
    sold: 'Sold',
    expired: 'Expired',
    voided: 'Voided',
    overdue: 'Overdue',
    paying: 'Payment pending',
    new: 'Draft',
    scheduled: 'Scheduled',
    denied: 'Denied',
    accepted: 'Signed',
    signed: 'Signed',
    semiSigned: 'Partially signed',
    active: 'Active',
    archived: 'Archived'
  },
  invoiceType: {
    invoice: 'Invoice',
    credit: 'Credit invoice',
    interest: 'Interest invoice'
  },
  terms: {
    conditionTerm: 'condition term',
    general: 'terms and conditions',
    termsAndConditions: 'terms and conditions',
    theTermsAndConditions: 'the terms and conditions',
    privacyPolicy: 'privacy notice'
  },
  invoiceMethod: 'Billing method',
  cashMethod: 'Cash method',
  bankgiro: 'Bankgiro',
  plusgiro: 'Plusgiro',
  domesticBankAcc: 'Bank account',
  clearingNo: 'Clearing-no',
  vat: {
    sharedTitle: 'VAT / Tax',
    vat: 'VAT',
    tax: 'tax',
    exVat: 'ex. {{- thing}}',
    incVat: 'incl. {{- thing}}',
    reverseVat: 'Reverse tax liability',
    reverseVatApplies: 'Reverse tax liability applies',
    vatIdentificationNumberShort: 'VAT identification No.',
    rate: 'VAT / Tax rate'
  },
  priceLabel: {
    unit: 'Price',
    hour: 'Hourly rate',
    m: 'Meter price',
    m2: 'Square meter price',
    m3: 'Cubic meter price',
    kg: 'Price per kg',
    litre: 'Price per liter',
    day: 'Price per day',
    km: 'Price per kilometer',
    mile: 'Price per mile',
    month: 'Price per month',
    week: 'Price per week',
    ton: 'Price per metric ton',
    year: 'Price per year',
    kWh: 'Price per kWh',
    markup: 'Markup'
  },
  unitType: {
    unitType: 'unit type',
    unitType_other: 'unit types',
    second: 's',
    minute: 'min',
    unit: 'pcs',
    hour: 'h',
    m: 'm',
    m2: 'm²',
    m3: 'm³',
    kg: 'kg',
    litre: 'litre',
    day: 'day',
    km: 'km',
    mile: 'mile',
    month: 'mon',
    week: 'week',
    week_other: 'weeks',
    ton: 'ton',
    year: 'year',
    kWh: 'kWh'
  },
  permission: {
    admin: 'Admin',
    creator: 'Creator',
    member: 'Member'
  },
  propertyDesignation: {
    propertyDesignation: 'Property designation',
    propertyId: 'Property designation',
    condominiumId: 'Apartment number',
    condominiumOrgNo: 'Housing association organization number'
  },
  notification: {
    notification: 'Notification',
    notification_other: 'Notifications',
    invitedProject: 'Invited to project',
    accepted: 'Document signed',
    semiSigned: 'Document partially signed',
    denied: 'Document denied',
    mailDropped: 'Email could not be delivered',
    viewed: 'Document viewed'
  },
  event: {
    event: 'event',
    event_other: 'events',
    mailDropped: 'Delivery failure email',
    smsDropped: 'Delivery failure SMS',
    viewed: 'Viewed',
    requestContract: 'Client requested contract',
    upgradeContract: 'Upgrade to contract',
    project: {
      created: 'Project created'
    },
    delivery: {
      sent: 'as email to',
      sentSms: 'as SMS to'
    }
  },
  rejectReasons: {
    category: {
      unclear: 'Unclear',
      price: 'Price',
      badges: 'References & Certificates',
      misc: 'Other'
    },
    unprofessional: 'Unprofessional impression.',
    'unclear-included': 'Unclear what is included.',
    'unclear-task-descriptions': 'Work tasks was unclear.',
    'unclear-language-complicated': 'Language was unclear / complicated.',
    'work-expensive': 'Work price too expensive.',
    'material-expensive': 'Material price too expensive.',
    'markup-expensive': 'Markup too expensive.',
    'price-too-low': 'Price feels too low to be true.',
    'missing-ref': 'Missing proper references.',
    'missing-cert': 'Missing proper certificates / competences.',
    'missing-f-skatt-insurance': 'Missing F-tax / liability insurance.',
    'chose-other': 'Chose another provider.',
    'bad-at-language': "Staff doesn't speak a language that I understand.",
    'expected-other': 'Not what I expected.'
  },
  services: {
    construction: 'Construction',
    design: 'Design',
    rut: 'RUT',
    photo: 'Photo',
    event: 'Event',
    tech: 'Tech'
  },
  region: {
    sweden: {
      enskildfirma: 'Individual company',
      handelsbolag: 'Trading company\n',
      aktiebolag: 'Limited company',
      economicAssociation: 'Economic Association'
    }
  },
  accountType: {
    free: 'Free',
    start: 'Start',
    base: 'Base',
    pro: 'Pro',
    premium: 'Pro',
    trial: 'Pro (trial)'
  },
  documentMentions: {
    // Client
    clientName: 'Client name',
    clientEmail: 'Client email',
    clientPhone: 'Client phone',
    clientCompany: 'Client company',
    clientAddress: 'Client address',
    clientZip: 'Client zip',
    clientCity: 'Client city',
    clientCountry: 'Client country',
    clientSocialNo: 'Client social security number',
    clientOrgNo: 'Client company registration number',
    // Sender
    senderName: 'Sender name',
    senderEmail: 'Sender email',
    senderPhone: 'Sender phone',
    senderCompany: 'Sender company',
    senderAddress: 'Sender address',
    senderZip: 'Sender zip',
    senderCity: 'Sender city',
    senderCountry: 'Sender country',
    // Document
    documentName: 'Document name',
    expiresAt: 'Document expires at',
    sentAt: 'Document sent at'
  },
  paymentInterval: {
    month: 'monthly',
    year: 'yearly'
  },
  designLabels: {
    typography: 'Typography',
    font: 'Font',
    title: 'Title',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    numberedList: 'Numbered list',
    bulletList: 'Bullet list',
    blockquote: 'Quote',
    paragraph: 'Text',
    line: 'Horizontal line',
    style: 'Style',
    size: 'Size',
    lineHeight: 'Line height',
    letterSpacing: 'Letter spacing',
    marginBottom: 'Margin bottom',
    letterCase: 'Letter case',
    letterCaseTypes: {
      initial: 'As typed',
      capitalized: 'Capitalized',
      uppercase: 'Uppercase'
    },
    color: 'Color',
    background: 'Background',
    contentWidth: 'Content width',
    padding: 'Padding',
    border: 'Border',
    borderWidth: 'Width',
    text: 'Text',
    section: 'Section',
    section_other: 'Sections'
  }
}
