import config from '@cling/config'
import appLang from '@cling/language/locales/sv/common'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  sign: 'signera',
  accept: 'acceptera',
  address: 'adress',
  and: 'and',
  answerFeedback: {
    intermediate: {
      title: 'Tack för att du delar dina tankar.'
    },
    no: {
      start: {
        action: 'Skicka in',
        placeholder: 'Vad bör vi förbättra?',
        text: 'Finns det något specifikt du önskar vi bör förbättra?',
        title: 'Tack för att du delar med dig av dina tankar.'
      },
      success: {
        action: 'Läs mer om $t(brand.name)',
        secondary: 'Tillbaka till dokumentet',
        text: 'Din feedback togs emot helt perfekt. Vårt team kommer att ta del av din feedback med stor lyhördhet.',
        title: 'Tack.'
      }
    },
    start: {
      no: 'Nej',
      title:
        'Tyckte du om att använda $t(brand.name) för att visa det här dokumentet?',
      yes: 'Ja'
    },
    yes: {
      action: 'Sätt igång gratis',
      secondary: 'Läs mer om $t(brand.name)',
      text: 'Skapa och skicka snygga dokument med $t(brand.name). Upptäck alla fördelar med att skicka webbaserade dokument med e-signering.',
      title: 'Börja använda $t(brand.name) du med.'
    },
    custom: {
      title: 'Tack.',
      text: 'Din feedback togs emot helt perfekt. Vårt team kommer att ta del av din feedback med stor lyhördhet.',
      action: 'Tillbaka till dokumentet'
    }
  },
  answerModal: {
    answerSuccess: 'Ditt svar togs emot helt perfekt.',
    bankId: {
      component: {
        defaultError: 'Något gick fel. Försök igen.',
        signingInterrupted: 'Signeringen avbröts, försök igen...',
        startOnThisDevice: 'Starta BankID på denna enhet',
        validations: {
          socialNo: {
            personalNumber: 'Personnumret är inte giltigt',
            required: 'Skriv in ditt personnummer'
          }
        },
        viewText: {
          canceled: {
            text: 'Signeringen avbröts. Var god och försök igen.',
            title: 'Signeringen avbröts'
          },
          error: {
            text: 'Ett fel inträffa. Var god och försök igen.',
            title: 'Ett fel inträffa'
          },
          form: {
            text: 'Skriv in ditt personnummer för att påbörja signeringen.',
            title: 'Signera dokumentet med BankID'
          },
          new: {
            spinnerText: 'Scanna QR-koden på din enhet.',
            text: 'Öppna BankID på din enhet för att signera dokumentet.',
            title: 'Signera dokument'
          },
          signing: {
            spinnerText: 'Var god signera dokumentet.',
            text: 'Öppna BankID på din enhet för att signera dokumentet.',
            title: 'Signering pågår'
          },
          waiting: {
            spinnerText: 'Väntar på att du ska öppna BankID.',
            text: 'Öppna BankID på din enhet för att signera dokumentet.',
            title: 'Signera dokument'
          }
        }
      },
      start: {
        action: 'Signera med BankID',
        text: 'Godkänn och signera dokumentet med BankID.',
        title: 'Signera med BankID'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    decline: {
      component: {
        label: 'Kommentar (valfritt)'
      },
      start: {
        action: 'Neka',
        text: 'För att neka dokumentet var god och bekräfta genom att klicka på knappen nedanför.',
        title: 'Neka dokumentet'
      },
      success: {
        text: 'Tack för att du lämna ett svar.',
        textFallback: '$t(answerModal.decline.success.text)',
        title: 'Du har nekat dokumentet.'
      }
    },
    signature: {
      component: {
        draw: {
          canvasLabel: 'Rita signatur',
          tab: 'Rita'
        },
        subTitle: 'Hur vill du signera?',
        terms:
          'Genom att signera dokumentet godkänner jag att den elektroniska signaturen är en representation av min handskrivna signatur och att den är lika giltig.',
        text: {
          canvasLabel: 'Förhandsgranskning',
          nameLabel: 'Signerad av',
          namePlaceholder: 'Skriv ditt namn',
          tab: 'Skriv'
        }
      },
      start: {
        action: 'Signera & godkänn',
        text: '',
        title: 'Signera dokumentet'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    versionError: {
      accept: { title: 'Gick inte att signera dokumentet' },
      decline: { title: 'Gick inte att neka dokumentet' },
      text: 'Dokumentet har modifierats medan du har läst den. Vänligen ladda om och ta del av det nya dokumentet för att lämna svar.',
      btn: 'Ladda om'
    },
    successText:
      'När dokumentet har blivit signerat av alla parter kommer du få en kopia skickat till din e-post {{email}} med en låst version av dokumentet.',
    successTextFallback:
      'När dokumentet har blivit signerat av alla parter kommer du på den här webbsidan kunna ladda ner en låst version av dokumentet.',
    successTextFallbackSingle: 'Dokumentet är nu signerat och komplett.',
    successTextSingle:
      'En bekräftelse har skickats till e-posten {{email}} med en låst version av dokumentet.',
    successTitle: 'Du har signerat dokumentet.'
  },
  approved: 'signerat',
  approx: 'ca',
  cancel: 'Avbryt',
  chapters: {
    answerButtons: {
      approveDescription:
        'Vi har meddelat {{- sender}} att dokumentet är signerat.',
      approveTitle: 'Dokumentet är signerat!',
      denyDescription:
        'Vi har meddelat {{- sender}} att du inte är intresserad.',
      denyTitle: 'Dokumentet är nekat',
      expiredTitle: 'Går ej längre besvara dokumentet',
      expiredDescription:
        'Dokumentets giltighetstid har passerats. Du kan inte längre besvara dokumentet. Vid frågor var god kontakta {{- sender}}.',
      editPropDes: 'Redigera fastighetsbeteckning',
      enterPropDes: 'Ange fastighetsbeteckning',
      inPersonSignatureHint:
        'Du har blivit markerad för att skriva under en fysisk kopia av detta dokument. Om du istället vill skriva under digitalt, kontakta',
      waitingDescription: 'Väntar på {{remaining}} st fler ska lämna svar.',
      youHave: 'Du har '
    },
    articleItem: {
      eligibleForDeduction: 'Berättigad för {{type}}-avdrag'
    },
    articleList: {
      changeShowVatTooltip: 'Ändra till att visa {{type}}',
      title: 'Arbetsmoment / varor'
    },
    attachments: {
      emptyPlaceholder: 'Inga bilagor har lagts till.'
    },
    packageGroup: {
      errorCallout: 'Välj minst ett alternativ',
      selectableOptions: 'Valbara alternativ'
    },
    packageItem: {
      add: 'Lägg till paket',
      select: 'Välj paket',
      selected: 'Valt paket'
    },
    parties: {
      allRiskAndLiability: 'Har allrisk- och ansvarsförsäkring',
      fTax: 'Har F-skatt'
    },
    pricingSummary: {
      blocks: {
        approx: 'Ungefärligt pris',
        deductionSubValue: 'Totalpris utan avdrag {{price}}',
        discount: 'Rabatt',
        maxPrice: 'Maxpris',
        maxPriceVat: 'Maxpris {{vat}}',
        net: 'Netto',
        price: 'Pris',
        rounding: 'Öresavrundning',
        subTitleArticles: 'Varav varor / moment med fast pris',
        summary: 'Sammanställning',
        total: 'Total',
        typeDeduction: 'Efter {{type}}-avdrag'
      },
      placeholder: {
        description:
          'Välj vilket paket som passar dig bäst genom att klicka på ',
        descriptionBold: 'Välj-knappen',
        title: 'Välj ett paket'
      },
      terms: {
        houseWork:
          'Totalpriset efter skattereduktion förutsätter att arbetet ' +
          'kvalificerar för skattereduktion, att beställaren har det angivna utrymmet för skattereduktion samt ' +
          'att det inte redan har utnyttjats för annat arbete. Beställaren ' +
          'ansvarar själv för att kontrollera dess uppgifter.',
        houseWorkApprox:
          'Priset för arbetet är ungefärligt. Tänk på att priset kan skilja sig med 15%. Om det gäller markarbeten ' +
          'rivningsarbeten eller lik. kan priset skilja sig med 25%. Läs mer om detta i de allmänna bestämmelserna.',
        reverseVat: 'Notera att omvänd skattskyldighet gäller.'
      }
    },
    termsDisclaimer: {
      description: 'Genom att godkänna dokumentet accepterar du de ',
      termsAndConditions: 'allmänna villkoren',
      title: 'Villkor'
    }
  },
  close: 'Stäng',
  company: 'företag',
  deduction: appLang.deduction,
  denied: 'nekat',
  deny: 'Neka',
  denyModal: {
    denyButton: 'Neka dokument',
    helpfulInformation:
      'Vi meddelar {{- senderName}} på {{- senderCompany}} att du har valt att neka dokumentet.',
    otherPlaceholder:
      'Frivilligt. Lämna en kommentar om varför du väljer att neka dokumentet.',
    subTitle: 'Bekräfta ditt svar genom att klicka på Neka.',
    successTitle: 'Du har nekat dokumentet',
    thanksAnswer: 'Tack för ditt svar!',
    title: 'Neka dokument'
  },
  detail_other: 'detaljer',
  document: 'Dokument',
  document_other: 'Dokument',
  documentDetails: {
    subTitle: 'Information om dokumentet.'
  },
  documentVoided: {
    title: 'Dokumentet är inte längre tillgängligt.',
    description: 'Avsändaren har valt att återkalla dokumentet.'
  },
  documentTemplateView: {
    emailTemplate: {
      expires: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.expires.title) $t(documentTemplateView.emailTemplate.expires.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      first: {
        content:
          'Du har fått ett dokument från {{company.name}}. Klicka på knappen för att läsa mer och lämna ett svar.',
        subject: '$t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.first.title) $t(documentTemplateView.emailTemplate.first.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.greetingName)'
      },
      reminder: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.reminder.title) $t(documentTemplateView.emailTemplate.reminder.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      viewed: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.viewed.title) $t(documentTemplateView.emailTemplate.viewed.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      }
    },
    greetingName: 'Hej{{clientFirstName}}!',
    readMore: 'Läs mer:',
    readMoreAndReply: 'Läs mer och lämna svar på:',
    reminder: 'Påminnelse!',
    sharedSubject: 'Dokument från {{company.name}} - {{document.data.name}}',
    smsContent:
      '{{company.name}} har skickat ett dokument till dig som du kan lämna svar på.',
    smsTemplate: {
      expires: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      first: {
        text: '$t(documentTemplateView.greetingName)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      reminder: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      viewed: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      }
    }
  },
  email: 'e-post',
  enterYour: 'Skriv in ditt {{thing}}',
  event: {
    accepted: 'Dokumentet undertecknades av {{by}}.',
    denied: 'Dokumentet nekades av {{by}}.',
    event_other: 'händelser',
    expired: 'Dokumentets giltighetstid har passerat.',
    requestChange: 'Dokumentet markerades manuellt som {{status}}.',
    sent: 'Dokument skickades till {{to}}.',
    statusChanged: 'Dokumentet markerades som {{status}}.',
    viewed: 'Har visats',
    viewedName: '{{name}} har visat dokumentet'
  },
  goBack: 'Tillbaka',
  less: 'mindre',
  login: 'Login',
  logout: 'Logout',
  myPages: {
    docCallout: {
      form: {
        text: 'Du kan nå alla tidigare dokument du mottagit till din e-post via dokumentbiblioteket.',
        title: 'Tips'
      },
      loginLink: 'inloggningslänk',
      goToMyPagesTitle: 'Gå till dina dokument'
    },
    documentItem: {
      approved: 'Godkändes',
      expired: 'Förföll'
    },
    documentsPlaceholder: 'Du har inte tagit emot några dokument.',
    pageDescription: 'Här samlas alla dina mottagna dokument.',
    requestForm: {
      alreadySentDescription:
        'En inloggningslänk har redan skickats till {{email}}. Det kan ta någon minut innan du får den.',
      alreadySentTitle: 'Inloggningslänken är redan skickad',
      emailFormat: 'Din e-post ska vara i formatet: namn@exempel.se',
      emailRequired: 'Skriv in din e-postadress',
      sendLink: 'Skicka inloggningslänk',
      sentDescription:
        'En inloggningslänk har skickats din din e-post, {{email}}, använd den för att logga in.',
      sentDescriptionSub:
        'Av säkerhetsskäl så kan du enbart logga in via en inloggningslänk.',
      sentTitle: 'Inloggningslänk skickad till din inkorg'
    },
    title: 'Mina sidor'
  },
  name: 'namn',
  orgNoShort: 'org-nr',
  participant_other: 'deltagare',
  sender: 'avsändare',
  phone: 'telefon',
  price: {
    approx: 'Ungefärligt pris',
    discount: 'Rabatt',
    fixed: 'Fast pris',
    markup: 'Påslag',
    maxPrice: 'maxpris',
    net: 'Netto',
    openAcc: 'Löpande räkning',
    openAccMaxPrice: 'Löpande räkning med maxpris',
    openAccPrice: 'Löpande räkning',
    price: 'pris',
    price_other: 'priser',
    pricing: 'prisform',
    proposal: 'Prisförslag',
    total: 'Totalpris'
  },
  print: 'Skriv ut{{thing,inlineSpace}}',
  download: 'Ladda ned{{thing,inlineSpace}}',
  reference: 'referens',
  replyBy: 'Svara senast',
  see: 'se',
  select: 'Välj{{thing,inlineSpace}}',
  send: 'Skicka{{thing,inlineSpace}}',
  series: appLang.series,
  show: 'Visa{{thing,inlineSpace}}',
  socialNo: 'personnummer',
  status: {
    accepted: 'Signerad',
    active: 'Pågående',
    archived: 'Arkiverad',
    expired: 'Förfallen',
    voided: 'Återkallad',
    completed: 'Klar',
    denied: 'Nekad',
    draft: 'Utkast',
    new: 'Utkast',
    semiSigned: 'Delsignerad',
    sent: 'Skickad',
    signed: 'Signerad'
  },
  summaryBottom: {
    chooseFrom: 'Välj bland flera paket',
    forDetails: 'för detaljer',
    noOfOptions: '{{count}} st',
    option: 'valbara',
    option_other: 'valbara',
    reverseVat: 'Moms {{- amount}}. Omvänd skattskyldighet gäller'
  },
  theDocument: 'dokumentet',
  theSender: 'avsändaren',
  times: 'gång',
  times_other: 'gånger',
  total: 'total',
  tryAgain: 'Försök igen',
  unitType: appLang.unitType,
  vat: appLang.vat
}
