import lang from '@cling/language'

import routerPageSettings from '@/router/routerPageSettings'

export default {
  alias: 'installningar',
  components: {
    content: () => import('@/views/account/settings/SettingsRootLayout.vue')
  },
  children: [
    {
      name: 'settings',
      path: 'general',
      alias: '/',
      components: {
        content: () => import('@/views/account/settings/SettingsTabDefault.vue')
      },
      meta: {
        documentTitle: lang.t('setting_other', { postProcess: 'capitalize' }),
        navbar: {
          type: 'default',
          title: lang.t('setting_other', { postProcess: 'capitalize' }),
          show: false
        },
        body: {
          fullSize: true
        }
      }
    },
    routerPageSettings
  ]
}
