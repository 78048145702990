<template>
  <div class="c-flex-row c-align-right" style="width: initial">
    <template v-if="!compact">
      <BaseAvatar
        v-for="(member, key) in [creator, ...members.slice(0, 2)]"
        :key="key"
        :disabled="!$can('projects', 'modify', projectId)"
        :size="29"
        :name="member.fullName"
        :image="member.AvatarPublicId"
        :email="member.email"
        :badge="member.id === creator.id"
        class="member-item"
        @click="openDropdown"
      />
      <BaseAvatar
        v-if="$can('projects', 'modify', projectId) && members.length > 2"
        :size="29"
        :group="true"
        :size-of-group="members.length - 2"
        class="member-item"
        @click="openDropdown"
      />
    </template>
    <CDropdown
      v-if="$can('projects', 'modify', projectId)"
      ref="memberList"
      position="is-bottom-left"
      style="margin-left: 1em"
    >
      <template #trigger>
        <CButton type="secondary" size="small" rounded style="font-weight: 600">
          {{ $t('_common:invite') }}
        </CButton>
      </template>
      <div class="dropdown-container">
        <div style="padding: 0.5em 1em 0 1em">
          <div
            class="is-size-7 has-text-grey-dark"
            style="margin-bottom: 0.75em"
          >
            {{ $t('_common:invite', { thing: $t('_common:member_other') }) }}
          </div>
          <CField
            grouped
            style="
              padding-bottom: 0.5em;
              border-bottom: 1px solid hsl(0, 0%, 90%);
            "
          >
            <CField
              :validator="v$.newMemberEmail"
              :messages="messages.email"
              expanded
            >
              <!-- grouped -->
              <CInput
                v-model="newMemberEmail"
                :placeholder="$t('_common:email').capitalize()"
                size="normal"
                filled
                @keyup.enter="sendMemberInvite"
              />
            </CField>
            <CButton
              :loading="isPostingMemberInvite"
              type="secondary"
              size="normal"
              @click="sendMemberInvite"
            >
              {{ $t('_common:send') }}
            </CButton>
          </CField>
        </div>
        <div style="overflow: auto; height: 100%; padding: 1em 0 2em 0">
          <div
            v-for="(member, i) in membersList"
            :key="member.id"
            :divided="i === 0"
            :disabled="isPosting"
            class="c-flex-row c-align-middle member-row"
            @click="
              member.isMember
                ? openMemberDropdown(member.id)
                : addMember(member.id)
            "
          >
            <BaseAvatar
              :name="member.fullName"
              :image="member.AvatarPublicId"
              :email="member.email"
              :size="26"
              style="margin-right: 1em"
            />
            <div class="truncate-text flex-grow">
              {{ member.fullName || member.email }}
            </div>
            <CDropdown
              v-if="member.isMember"
              :ref="`${member.id}`"
              position="is-bottom-left"
            >
              <template #trigger>
                <CButton
                  size="small"
                  type="none"
                  pattern="secondary"
                  @click="openMemberDropdown(member.id)"
                >
                  {{ member.isLeader ? $t('leader') : $t('added') }}
                </CButton>
              </template>
              <CDropdownItem
                :tooltip="
                  !canBecomeProjectLeader(member)
                    ? {
                        content: member.isLeader
                          ? $t('promoteTooltipLeader')
                          : $t('promoteTooltip')
                      }
                    : null
                "
                :disabled="isPosting || !canBecomeProjectLeader(member)"
                class="text-right"
                @click="setAsProjectLeader(member)"
              >
                {{ $t('promoteButton') }}
              </CDropdownItem>
              <CDropdownItem
                :tooltip="member.isLeader ? $t('removeTooltip') : null"
                :disabled="isPosting || member.isLeader"
                type="danger"
                class="text-right"
                @click="removeMember(member.id)"
              >
                {{ $t('removeButton') }}
              </CDropdownItem>
            </CDropdown>
          </div>
        </div>
      </div>
    </CDropdown>
  </div>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import CField from '@cling/components/ui/FormField'
import { global } from '@cling/store/action-types'

import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

import BaseAvatar from '@/components/base/BaseAvatar.vue'

export default {
  i18nOptions: {
    namespaces: 'views',
    keyPrefix: 'account.project.member'
  },
  name: 'ProjectMemberList',
  components: {
    BaseAvatar,
    CDropdown,
    CDropdownItem,
    CField
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    members: {
      type: Array,
      default: () => []
    },
    creator: {
      type: Object,
      default: () => ({})
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      newMemberEmail: {
        required,
        email,
        unique: val =>
          !Object.values(this.companyUsers)
            .map(({ email }) => email)
            .includes(val.toLowerCase())
      }
    }
  },
  data() {
    return {
      newMemberEmail: ''
    }
  },
  computed: {
    ...mapGetters({
      companyUsers: 'companyUsers/all',
      isPosting: 'projects/isPosting',
      isPostingMemberInvite: 'companyUsers/isPosting'
    }),
    messages() {
      return {
        email: {
          required: this.$t('validation.required'),
          email: this.$t('validation.email'),
          unique: this.$t('validation.unique')
        }
      }
    },
    membersList() {
      const memberIds = [this.creator.id, ...this.members.map(m => m.id)]
      return (
        Object.keys(this.companyUsers)
          .map(x => parseInt(x, 10))
          // Sort owner first the rest of remaining users in DESC id order
          .sort((a, b) => (b === this.creator.id ? 1 : b - a))
          .map(id => ({
            ...this.companyUsers[id],
            isMember: memberIds.includes(id),
            isLeader: this.creator.id === id
          }))
      )
    }
  },
  created() {
    this.loadCompanyUsers()
  },
  methods: {
    ...mapActions({
      loadCompanyUsers: global.LOAD_COMPANY_USERS,
      submitProjectMember: global.SUBMIT_PROJECT_MEMBER,
      deleteProjectMember: global.DELETE_PROJECT_MEMBER,
      setProjectLeader: global.SET_PROJECT_LEADER,
      createUser: global.CREATE_COMPANY_USER
    }),
    addMember(id) {
      this.submitProjectMember({ projectId: this.projectId, companyUserId: id })
    },
    removeMember(id) {
      this.deleteProjectMember({ projectId: this.projectId, companyUserId: id })
    },
    setAsProjectLeader(member) {
      this.setProjectLeader({
        projectId: this.projectId,
        companyUserId: member.id
      })
    },
    canBecomeProjectLeader(member) {
      const isLeaderAlready = member.id === get(this, 'creator.id')
      const manageOwn = get(member, 'CompanyUserPermission.manageOwnProjects')
      const manageProjects = get(member, 'CompanyUserPermission.manageProjects')
      return isLeaderAlready ? false : manageOwn || manageProjects
    },
    openDropdown() {
      if (!this.$can('projects', 'modify', this.projectId)) return
      this.$refs.memberList.toggle()
    },
    openMemberDropdown(id) {
      this.$refs[id][0].toggle()
    },
    async sendMemberInvite() {
      this.v$.$touch()
      if (this.v$.$invalid) return

      const body = {
        email: this.newMemberEmail
      }

      const userId = await this.createUser({ body })
      if (userId) this.addMember(userId) // Add the new user to the project as well
      this.newMemberEmail = ''
      this.v$.$reset() // Restore validations
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

.member-item {
  cursor: pointer;
  transition: transform 120ms $easeOutBack;
}

.member {
  &-row {
    padding: 5px 1em;
    border-radius: 0.5em;
    height: 42px;
    cursor: pointer;
    &:hover {
      background-color: $grey-lightest-2;
    }
    &:focus,
    &:active {
      background-color: $grey-lightest;
    }
  }
}

.dropdown-container {
  width: 390px;
  height: 400px;
  margin: 0 auto; // For mobile dropdown
  max-width: 100%; // For mobile dropdown
  overflow: hidden;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}
</style>
