export default {
  account: {
    app: {
      overlayAppLoader: {
        subTitle: 'Dina vänner på {{brand,capitalize}}',
        quote1: 'Upprättar en säker anslutning...',
        quote2: 'Drick mycket kaffe om du kan!',
        quote3: 'Se till att sova ordentligt',
        quote4: 'Riktar om satelliter...',
        quote5: 'Kopplar upp modermodemet...',
        quote6: 'Ha en fantastisk dag!'
      },
      overlayNetworkError: {
        title: 'Oops! Ett fel inträffade',
        subTitle: 'Pröva att ladda om sidan eller skriv till oss på chatten.',
        networkError: {
          title: 'Ingen internetuppkoppling',
          subTitle:
            'Sidan har pausats tills att du får din internetanslutning tillbaka.'
        }
      }
    },
    document: {
      calloutDeniedReasons: {
        title: 'Din kund nekade men det finns hopp!',
        description:
          'Din kund angav följande anledningar till varför de valde att neka dokumentet, {{documentName}}:',
        commentFromClient: 'Kommentar från kund',
        button: 'Ändra & skicka igen'
      },
      invoice: {
        quickOptions: 'Snabbval',
        scheduledSend: 'Skickas',
        empty: 'Du har inte skapat några dokument ännu.',
        actions: {
          showProject: 'Gå till projekt',
          edit: 'Redigera',
          sendReminder: 'Skicka påminnelse',
          sendReminderTooltip:
            'Ändra dokumentets status för att kunna skicka igen.',
          duplicate: 'Duplicera',
          invoice: 'Fakturera',
          invoiceTooltip: 'Markera dokumentet som godkänt först',
          print: 'Skriv ut',
          upgradeSignature: 'Uppgradera till avtal',
          remove: 'Ta bort',
          removeDialog: {
            title: 'Ta bort dokumentet',
            message: 'Är du säker på att du vill ta bort dokumentet?',
            cancel: 'Avbryt',
            submit: 'Ta bort dokumentet'
          }
        }
      },
      statusDropdown: {
        tooltip: 'Du är ej behörig att ändra status'
      },
      // ! will probably be removed !
      articleItem: {
        autoCompletePlaceholder: 'T.ex. arbete & material',
        hidePriceLabel: 'Dölj pris för kund',
        priceTypeShort: {
          approx: 'Ca. pris',
          openAcc: 'Löp. pris'
        },
        validations: {
          internalArticleName: {
            maxLength: 'Namnet får vara max 255 tecken långt',
            required: 'Ge artikeln ett namn om du anger pris'
          },
          internalQuantity: {
            minValue: 'Minsta antal är 1 st',
            required: 'Ange ett antal för raden'
          }
        }
      }
    },
    documentForm: {
      misc: {
        addSample: "Klicka på '{{thing}}' för att testa exemplet."
      },
      contentItems: {
        header: {
          redOverlay: {
            previewDocumentName:
              '<tspan x="160.008" y="133.227">Offert</strong></tspan>',
            previewPostText:
              '<tspan x="174.453" y="228.818">Avsedd för Sue Doe&#10;</tspan><tspan x="177.878" y="235.818">Av James Smith</tspan>',
            documentName: '<p data-typography="title">Offert</p>',
            postText:
              '<p><span>Avsedd för {{clients.0.name}}</span></p><p><span>Av {{companyUser.fullName}}</span></p>'
          },
          greenLeaf: {
            previewDocumentName: '<tspan x="174" y="132.273">Offert</tspan>',
            previewPostText:
              '<tspan x="174" y="216.545">Avsedd för Sue Doe&#10;</tspan><tspan x="174" y="226.545">Av James Smith</tspan>',
            documentName: '<p data-typography="title">Offert</p>',
            postText:
              '<p><span>Avsedd för {{clients.0.name}}</span></p><p><span>Av {{companyUser.fullName}}</span></p>'
          },
          darkGreenSkyscraper: {
            previewDocumentName: '<tspan x="27" y="80.2727">Offert</tspan>',
            previewPostText:
              '<tspan x="27" y="114.682">Avsedd för Sue Doe&#10;</tspan><tspan x="27" y="123.682">Av James Smith</tspan>',
            documentName: '<p data-typography="title">Offert</p>',
            postText:
              '<p><span>Avsedd för {{clients.0.name}}</span></p><p><span>Av {{companyUser.fullName}}</span></p>'
          },
          purpleGossip: {
            previewDocumentName: '<tspan x="45" y="132.273">Offert</tspan>',
            previewPostText:
              '<tspan x="45" y="216.545">Avsedd för Sue Doe&#10;</tspan><tspan x="45" y="226.545">Av James Smith</tspan>',
            documentName: '<p data-typography="title">Offert</p>',
            postText:
              '<p><span>Avsedd för {{clients.0.name}}</span></p><p><span>Av {{companyUser.fullName}}</span></p>'
          },
          mauveComputer: {
            previewDocumentName: '<tspan x="27" y="176.273">Offert</tspan>',
            previewPostText:
              '<tspan x="27" y="210.682">Avsedd för Sue Doe&#10;</tspan><tspan x="27" y="219.682">Av James Smith</tspan>',
            documentName: '<p data-typography="title">Offert</p>',
            postText:
              '<p><span style="color: #FFC1E6">Avsedd för {{clients.0.name}}</span></p><p><span style="color: #FFC1E6">Av {{companyUser.fullName}}</span></p>'
          }
        },
        coverBlock: {
          violetTestimonial: {
            previewTitle: '<tspan x="89" y="163.5"> - Kund namn</tspan>',
            previewText:
              '<tspan x="89" y="89.5">Inkludera en rekommendation från en nöjd kund här. </tspan><tspan x="89" y="108.5">Det viktiga är att kunden pratar om det </tspan><tspan x="89" y="127.5">positiva i att jobba med dig.&#10;</tspan>',
            text: `<blockquote>
              <p>
                <span style="color: hsla(297,93.75%,87.45%,1);">
                  Inkludera en rekommendation från en nöjd kund här. Det viktiga är att kunden pratar om det positiva i att jobba med dig.
                </span>
              </p>
              <p>
                <span style="color: hsla(297,93.75%,87.45%,1);"><br>- Kund namn</span>
              </p>
            </blockquote>`
          },
          ourWorkCouch: {
            previewText: '<tspan x="90.102" y="129.545">Vårt arbete</tspan>',
            text: '<p data-typography="title" style="text-align: center;">Vårt arbete</p>'
          },
          flyoverSkyscraper: {
            text: '<p data-typography="title" style="text-align: center;"><span style="color: hsla(0,0%,100%,1);">Vårt arbete</span></p>'
          },
          qualityGradient: {
            previewTitle: '<tspan x="139.271" y="122.364">Kvalitet</tspan>',
            previewText:
              '<tspan x="125.29" y="143.545">Vi är fast beslutna att leverera arbete som</tspan><tspan x="155.877" y="151.545">överträffar era förväntningar.</tspan>',
            text: '<p data-typography="title" style="text-align: center;"><span style="color: hsla(0,0%,100%,1);">Kvalitet</span></p>'
          },
          palmLeafProcess: {
            previewTitle: '<tspan x="88" y="103.636">Process</tspan>',
            previewText:
              '<tspan x="88" y="121.909">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </tspan><tspan x="88" y="131.909">Nam hendrerit nisi sed sollicitudin pellentesque. Nunc </tspan><tspan x="88" y="141.909">posuere purus rhoncus pulvinar aliquam. Ut aliquet </tspan><tspan x="88" y="151.909">tristique nisl vitae volutpat. Nulla aliquet porttitor </tspan><tspan x="88" y="161.909">venenatis. </tspan>',
            text: `
              <p data-typography="title">
                <span style="color: hsla(41.16,100%,76.57%,1);">Process</span>
              </p>
              <p>
                <span style="color: hsla(41.16,100%,76.57%,1);">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique nisl vitae volutpat. Nulla aliquet porttitor venenatis.
                </span>
              </p>
            `
          }
        }
      },
      attachments: {
        fileNamePlaceholder: 'Ge filen ett namn'
      },
      clients: {
        title: 'Vem är din kund?', // todo: need new wording?
        subTitle: 'Lägg till en mottagare.',
        errorMessage: 'Lägg till en mottagare',
        search: {
          label: 'En mottagare jag redan har',
          placeholder: 'En befintlig mottagare',
          empty: 'Inga resultat hittades för'
        },
        add: {
          extra: 'Lägg till fler mottagare',
          new: 'Ny mottagare',
          me: 'Lägg till mig!',
          fake: 'Lägg till en låtsaskund'
        }
      },
      // text editor, templates etc
      extraWork: {
        title: 'Ändringar & Tilläggsarbeten'
      },
      settings: {
        backgroundImage: 'Bakgrundsbild',
        backgroundFilter: 'Bakgrundsfilter',
        priceSummaryBackground: 'Prisblock',
        headingColor: 'Färg på rubriklinjer'
      },
      titleAndText: {
        title: 'Titel',
        text: 'Skriv något smart',
        error: 'Skriv en liten text eller klicka på ta bort.'
      },
      admin: {} // TODO
    },
    notification: {
      setAllRead: 'Markera alla som lästa',
      upToDate: 'Du är up-to-date!',
      showAll: 'Visa alla notifikationer',
      showEarlier: 'Visa tidigare notiser',
      markAsRead: 'Markera som läst'
    },
    subscription: {
      callout: {
        title: 'Din kontoplan',
        upgradeButton: 'Skaffa'
      },
      premiumStripe: {
        title: 'Go Pro ->',
        titleEdit: 'Ändra plan',
        subTitle: 'Uppgradera ditt konto för att låsa upp fler fördelar.',
        subTitleEdit: 'Justera din betalningsplan.',
        selectAccountType: 'Välj plan',
        paymentPlan: 'Välj period',
        paymentMethod: 'Hur vill du betala?',
        paymentQuantity: 'Välj antal licenser',
        paymentQuantityTooltip:
          'Antal medlemmar som ska kunna skapa & skicka dokument.',
        paymentInformation: 'Ange betalningsuppgifter',
        invoiceRestrictedPlan:
          'Faktura är enbart tillgänglig vid årsvis betalning.',
        submitButton: 'Uppgradera till {{plan,capitalize}}',
        errorMessage: 'Ett fel inträffa, vänligen försök igen.',
        quantityDisabled:
          'Byt till <strong>Pro</strong> för att lägga till fler licenser.',
        existingCard:
          'Utdraget kommer dras från kortet som slutar med {{last4}}.',
        changeCard: 'Byt kort',
        changeEmail: 'Byt e-post',
        plans: {
          starter: {
            name: 'Starter',
            text: `
              <div>1 användare enbart.</div>
              <div>Skicka 5 st dokument per månad.</div>
              <div>BankID-signaturer</div>
            `
          },
          premium: {
            name: 'Pro',
            text: `
              <div>Utökad e-signering.</div>
              <div>Statistik.</div>
              <div>Integrationer.</div>
            `
          }
        },
        list: {
          securePayment: 'Säker kortbetalning.',
          satisfiedCustomer: '30 dagar nöjdkund-garanti.'
        },
        success: {
          title: 'Grattis!',
          subTitle: 'Ditt konto har nu uppgraderats.',
          subTitleEdit: 'Din betalningsplan har uppdaterats.',
          closeButton: 'Fortsätt där du var'
        },
        promotion: {
          addPromo: 'Rabattkod?',
          placeholder: 'Ange rabattkod',
          submit: 'OK'
        },
        validations: {
          organisationNumber: {
            isValidOrgNoWrapper: 'Ange ett giltigt org-nr'
          },
          email: {
            required: 'Din E-postadress saknas',
            email: 'Ogitlitgt format, se ex. namn@exempel.se'
          },
          phone: {
            required: 'Ange din telefon'
          },
          quantity: {
            required: 'Ange antal licenser',
            minValue: 'Ange ett positivt tal'
          }
        }
      }
    },
    themeBuilder: {
      callout: {
        title: 'Designa ditt dokument',
        description:
          'Använd vår temaskapare för att skapa en snygg mall som du kan använda till alla dina dokument.',
        openText: 'Öppna temaskaparen',
        hideText: 'Dölj meddelande'
      },
      preview: {
        colorsTitle: 'En stor titel',
        colorsSubTitle: 'Mindre rubrik',
        fontsTitle: 'Vi är en modern digital byrå',
        cover1: 'About us',
        cover2: 'Process',
        header1: 'Offert'
      },
      complete: {
        loading: {
          title: 'Skapar ditt tema',
          text: {
            buildingDocument: 'Bygger upp dokumentstruktur...',
            designingElements: 'Formger element med design...',
            addingText: 'Lägger till exempeltext...',
            done: 'Klar'
          }
        },
        success: {
          title: 'Din design är redo',
          text: 'Vill du förhandsgranska och skapa ett dokument med din nyligen skapade design?',
          createButton: 'Skapa dokument med min design',
          continueButton: 'Fortsätt där du var'
        }
      }
    },
    // General Account components
    menu: {
      switchAccounts: 'Växla konton',
      misc: 'Mer',
      goPro: 'Go Pro',
      settings: {
        styling: 'Ställ in utseende',
        chat: 'Chatta med oss',
        addName: 'Lägg till namn'
      },
      onboardingCallout: {} // TODO
    },
    search: {
      noResults: 'Hittade inga resultat',
      title: 'Sök på nånting',
      subTitle: 'Projekt, kunder m.m.',
      filterLabelAll: 'Allt'
    },
    autoSaveNotes: {
      placeholder:
        'Skriv något du vill komma ihåg. \nAnteckningar är enbart synlig för dig & dina kollegor.',
      updated: {
        posting: 'Sparar...',
        recent: 'Uppdaterades nyss',
        ago: 'Sparades {{time}}'
      }
    },
    connectedUsersModal: {
      title: 'Mina konton',
      loggedInTooltip: 'Inloggad som just nu',
      button: 'Växla konto',
      successMessage: 'Standardkonto har uppdaterats',
      default: {
        tooltip: 'Konto du vanligtvis vill loggas in på',
        text: 'Standardkonto'
      }
    },
    oldBrowserWarning: {
      title: 'Du använder en gammal webbläsare',
      description:
        'För att {{brand,capitalize}} ska fungera korrekt och av säkerhetsskäl rekommenderar vi att du <strong>uppdaterar din webbläsare</strong> eller använder <strong>Google Chrome.</strong>'
    },
    navbarSearch: {
      tooltip: 'Sök efter något'
    }
  },
  document: {
    myPagesDrawer: {
      pendingAnswer: 'Har ännu inte besvarat dokumentet.'
    },
    summaryTop: {
      titleMissing: 'Titel saknas',
      sentAtPlaceholder: 'Sätts vid utskick'
    },
    summaryBottom: {
      chooseFrom: 'Välj bland flera paket',
      forDetails: 'för detaljer',
      noOfOptions: '{{count}} st',
      option: 'valbara',
      option_other: 'valbara',
      reverseVat: 'Moms {{- amount}}. Omvänd skattskyldighet gäller'
    },
    coverBlock: {
      defaultText: 'En intressant titel.'
    },
    videoPlayer: {
      subTitle: 'Klistra in en videolänk från YouTube, Vimeo eller Wistia.',
      placeholder: 'Klistra in videolänk',
      validations: {
        url: {
          isValid: 'Ingen giltig video kunde hittas'
        }
      }
    },
    chapters: {
      header: {
        postText:
          '<p><span>Avsedd för {{clients.0.name}}</span></p><p><span>Av {{companyUser.fullName}}</span></p>'
      },
      multiGroupPricing: {
        title: 'Prissummering'
      },
      packageGroup: {
        title: 'Omfattning'
      },
      parties: {
        title: 'Parter',
        fTax: 'Har F-skatt',
        allRiskAndLiability: 'Har allrisk- och ansvarsförsäkring',
        placeholder: {
          title: 'Lägg till en kund',
          subTitle: 'Du har inte lagt till någon mottagare.'
        },
        missingSender: {
          title: 'Lägg till kontaktinfo',
          description:
            'Skriv in ditt företagsnamn och kontaktuppgifter så att det är med på dokumentet.'
        }
      },
      workPackage: { title: 'Omfattning' },
      warningInfo: { title: 'Avrådande' },
      estimatedTime: { title: 'Tider' },
      extraWorkTimeImpact: { title: 'Tider' },
      extraWorkConstructionPricingList: {
        title: 'Ändringar och tilläggsarbeten'
      },
      paymentTerms: { title: 'Betalning' },
      misc: { title: 'Övrigt' },
      subChapters: {
        clients: { title: 'Beställare' },
        sender: { title: 'Utförare' },
        workAddress: { title: 'Adress där arbetet ska utföras' },
        description: { title: 'Beskrivning' },
        travelCosts: { title: 'Resor' },
        attachments: { title: 'Bilagor' },
        notIncluded: { title: 'Arbete, material & övrigt som inte ingår' },
        warningInfo: {
          title: 'Arbete som beställaren vill ha utfört trots avrådande'
        },
        estimatedTimeToComplete: { title: 'Kalendertid för genomförande' },
        estimatedWorkStartAndEndAt: { title: 'Datum för genomförande' },
        extraWorkConstructionPricingList: {
          title: 'Vid uppkommande av tilläggsarbeten'
        },
        extraWorkTravelCosts: { title: 'Resekostnader för tilläggsarbeten' },
        paymentTerms: { title: 'Betalning för arbetet sker enligt' },
        miscAgreement: { title: 'Övriga överenskommelser' },
        docIsBinding: { title: 'Utställarens rätt' },
        extraWorkTimeImpact: {
          title: 'Tidspåverkan'
        }
      }
    },
    printButton: { tooltip: 'Spara eller skicka dokumentet först' },
    addonDocumentCallout:
      'Det här dokumentet är en ändring- eller ett tilläggsavtal till dokumentet',
    acceptModal: {
      title: 'Du har godkänt dokumentet!',
      description:
        'Tack för ditt svar! Vi har meddelat {{- senderName}} att du har godkänt dokumentet.',
      propDesTitle: 'Snabba på ditt ROT-avdrag',
      propDesDescription:
        'Gör det smidigt för dig att få ditt ROT-avdrag. Fyll i din fastighetsbeteckning.',
      propDesButton: 'Fyll i fastighetsbeteckning',
      backToDocument: 'Tillbaka till dokumentet',
      signature: {
        title: 'Signera för att acceptera',
        subTitle: 'Hur vill du signera?',
        terms:
          'Genom att signera dokumentet godkänner jag att den elektroniska signaturen är en representation av min handskrivna signatur och att den är lika giltig.',
        action: 'Signera & acceptera',
        draw: {
          tab: 'Rita',
          canvasLabel: 'Rita signatur'
        },
        text: {
          tab: 'Skriv',
          nameLabel: 'Signerad av',
          namePlaceholder: 'Skriv ditt namn',
          canvasLabel: 'Förhandsgranskning'
        }
      },
      registerCallout: {
        text: 'Vill du också skicka snygga offerter & avtal?',
        action: 'Skapa gratiskonto'
      }
    },
    propertyDesignationModal: {
      title: 'Ange fastighetsbeteckning',
      subTitle:
        'För att det ska gå smidigare för dig att få ditt ROT-avdrag så behöver {{- senderName}} din fastighetsbeteckning.',
      howToFind: 'Hur hittar jag min fastighetsbeteckning?',
      goto1: 'Gå in på',
      goto2: 'Lantmäteriet',
      goto3:
        'och under Mina sidor så kan du hitta din fastighetsbeteckning. Du loggar in med BankID.',
      whereTitle: 'Var utförs arbetet?',
      house: 'Villa',
      realEstate: 'Fastighet',
      condominium: 'Bostadsrätt',
      apartment: 'Lägenhet',
      propertyDesignation: 'Fastighetsbeteckning',
      propertyDesignationPlaceholder: 'Din fastighetsbeteckning',
      condominiumId: 'Lägenhetsnummer',
      condominiumOrgNo: 'Bostadsrättsföreningens Org-nr',
      condominiumIdPlaceholder: '4 siffror',
      condominiumOrgNoPlaceholder: '10 siffror',
      savedTitle: 'Din fastighetsbeteckning sparades!',
      savedSubTitle:
        'Tack för att du fyllde i informationen. Vi meddelar {{- senderName}} så att de kan förbereda avdraget.',
      validations: {
        condominiumId: {
          required: 'Ange ditt lägenhetsnummer',
          minLength: 'Det ska vara 4 siffror',
          maxLength: 'Det ska vara 4 siffror'
        },
        condominiumOrgNo: {
          required: 'Ange din bostadensrätts org-nr'
        },
        realEstateId: {
          required: 'Ange din fastighetsbeteckning'
        }
      }
    }
  },
  forms: {
    article: {
      namePlaceholder: 'Namn på artikeln',
      pricePlaceholder: 'Pris per enhet',
      artNoPlaceholder: 'Frivilligt',
      dialog: {
        title: 'Osparade ändringar',
        message: 'Är du säker på att du vill avbryta?'
      },
      validations: {
        name: {
          required: 'Ge artikeln ett namn',
          maxLength: 'Namnet får max vara 256 tecken'
        },
        price: {
          decimal: 'Priset måste vara ett tal',
          minValue: 'Ange ett positivt tal'
        }
      }
    }
  },
  signup: {
    form: {
      consent: {
        label: 'Jag godkänner de',
        link: 'allmänna villkoren'
      },
      submit: {
        text: 'Skapa konto',
        free: 'det är gratis'
      },
      verify: {
        title: 'Verifiera din e-post',
        description:
          'Klicka på länken i e-post du fått för att skapa en till användare för {{email}}.'
      },
      validations: {
        companyName: {
          required: 'Ange företagsnamn',
          maxLengthValue: 'Max {{maxLength}} tecken'
        },
        email: {
          required: 'Ange E-post',
          email: 'E-post är ogiltig'
        },
        password: {
          required: 'Ange lösenord',
          minLength: 'Minst 8 tecken'
        }
      },
      existingAccount: {
        title: 'Befintlig användare',
        message:
          'Det finns redan en användare med den här e-posten, vill du ändå skapa ett nytt konto?',
        reset: 'Återställ lösenord',
        submit: 'Skapa nytt konto'
      }
    }
  },
  base: {
    avatar: {
      tooltip: 'Okänd användare'
    },
    uploadList: {
      dropArea: 'Dra och släpp filer eller klicka här.'
    },
    table: {
      empty: 'Här var det tomt!'
    },
    textEditorTemplates: {
      emptyTitle: 'Du har inga mallar sparade',
      emptySubTitle: 'Skriv en text ovanför & välj spara',
      button: 'Spara som mall'
    }
  },
  infiniteLoader: {
    noMore: 'Här tog det slut',
    noResults: 'Här tog det slut'
  },
  cling: {
    message: {
      tip: 'Tips'
    }
  },
  adminSettings: {} // TODO
}
