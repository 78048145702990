import { getCertificates, postCompanyCertificates } from '@cling/api'
import lang from '@cling/language'
import globalActionTypes from '@cling/store/action-types'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'certificates'

const { LOAD_CERTIFICATES, DO_SET_COMPANY_CERTIFICATES, SUBMIT_CERTIFICATES } =
  actionTypes

const {
  SET_CERTIFICATES_FETCHING,
  SET_CERTIFICATES_POSTING,
  CLEAR_CERTIFICATES,
  SET_CERTIFICATES
} = mutationTypes

export default {
  /**
   * @name LOAD_CERTIFICATES
   * Load all certificates
   * @param {Object} Vuex object
   */
  async [LOAD_CERTIFICATES]({ commit }) {
    try {
      commit(SET_CERTIFICATES_FETCHING, true)
      const { data } = await getCertificates()

      commit(CLEAR_CERTIFICATES)
      commit(SET_CERTIFICATES, { data })
      commit(SET_CERTIFICATES_FETCHING, false)
    } catch (err) {
      commit(SET_CERTIFICATES_FETCHING, false)
      this.handleError(err, {
        object: 'certificate',
        fallbackCode: 'certificate.get',
        action: `${moduleName}/${LOAD_CERTIFICATES}`
      })
    }
  },

  /**
   * @name DO_SET_COMPANY_CERTIFICATES
   * Set certificate ids for authenticated company
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number[]} object.certificateIds Array of certificate ids to replace and use
   */
  async [DO_SET_COMPANY_CERTIFICATES]({ commit }, { certificateIds = [] }) {
    try {
      commit(SET_CERTIFICATES_POSTING, true)

      await postCompanyCertificates({ certificateIds })
      await this.dispatch(
        `companies/${globalActionTypes.DO_LOAD_CURRENT_COMPANY}`
      )

      commit(SET_CERTIFICATES_POSTING, false)
    } catch (err) {
      commit(SET_CERTIFICATES_POSTING, false)
      throw err
    }
  },

  /**
   * @name SUBMIT_CERTIFICATES
   *  Submit certificate ids to use for authenticated company
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number[]} object.certificateIds Array of certificate ids to replace and use
   * @returns {Boolean} True if submitted, otherwise false
   */
  async [SUBMIT_CERTIFICATES]({ dispatch }, { certificateIds }) {
    try {
      await dispatch(DO_SET_COMPANY_CERTIFICATES, { certificateIds })

      this.dispatch('application/SHOW_MESSAGE', {
        displayType: 'notification',
        type: 'success',
        message: lang.t('updatedThing', { thing: lang.t('certificate_other') })
      })
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'certificate',
        fallbackCode: 'certificate.submit',
        action: `${moduleName}/${SUBMIT_CERTIFICATES}`,
        actionPayload: arguments[1]
      })
      return false
    }
  }
}
